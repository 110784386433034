import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Welcome from "./welcome/Welcome";
import YLDToken from "./yldToken/YLDToken";
import Progress from "./progress/Progress";
import Overview from "./overview/Overview";
import { getUserProfileApi } from "../../apis/profile/getUserProfile";
import { setUser } from "../../redux/actions/user";
import { WalletApi, TokenApi, HoldingsApi } from "../../apis/wallet/WalletApi";
import { setWallet, setCoins, setHoldings } from "../../redux/actions/wallet";
import { useDispatch } from "react-redux";
import Chart from "./chart/chart";

import { GetPlansApi } from "../../apis/invest/InvestApi";
import { setPlans } from "../../redux/actions/plans";

function Dashboard() {
  const dispatch = useDispatch();

  const [userResult, setUserResult] = useState(false);
  const [holdingResult, setHoldingResult] = useState(false);

  const getUserProfile = async () => {
    const result = await getUserProfileApi();
    if (result === false) {
    } else {
      if (result.data.response) {
        setUser(result.data.response, dispatch);
        setUserResult(true);
      }
    }
  };

  const getWallet = async () => {
    const res = await WalletApi();
    if (res === false) {
      console.log("Error");
    } else {
      // if (result.data.response.result) {
      setWallet(res.data.response.result, dispatch);
      // }
    }
  };

  const getToken = async () => {
    const result = await TokenApi();
    if (result === false) {
      console.log("Error");
    } else {
      if (result.data.response) {
        setCoins(result.data.response, dispatch);
      }
    }
  };

  const getHoldings = async () => {
    const result = await HoldingsApi();
    if (result.data.isSuccess) {
      setHoldings(result.data, dispatch);
      setHoldingResult(true);
    } else {
      console.log("err");
    }
  };

  async function GetPlansFunction() {
    const result = await GetPlansApi();
    if (result.isSuccess) {
      setPlans(result.data.response, dispatch);
    } else {
      console.log("Plans Not found");
    }
  }
  useEffect(() => {
    getUserProfile();
    getWallet();
    getToken();
    getHoldings();
    GetPlansFunction();
  }, []);

  return (
    <Layout>
      {/* <div className=" max-h-full">Hello</div> */}
      <div className="flex md:flex-row flex-col gap-2 mx-0 lg:mx-10 max-h-full">
        <div className="bg-white px-4 lg:px-6 py-2 rounded-2xl w-full md:w-2/3 max-h-full">
          <p className="font-bold  text-3xl py-4 color-[#002C3A]">Dashboard</p>
          <div className="">{userResult && <Welcome />}</div>
          <div>
            <Chart />
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full md:w-1/3 max-h-full">
          <div className="h-5/10">
            {" "}
            <Overview />
          </div>
          <div className="h-2/10">
            <Progress />
          </div>
          <div className="h-3/10">
            <YLDToken />
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default Dashboard;
