import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../components/styledComponents/Buttons/Button";
import Input from "../../components/styledComponents/Input/Input";
// import Modal from "react-modal"
import BTC from "../../assets/images/btc.svg";
import YLD from "../../assets/images/yld.svg";
import ETH from "../../assets/images/eth.svg";
import USDT from "../../assets/images/usdt.svg";
import USDC from "../../assets/images/usdc.svg";
import DownArrow from "../../assets/images/down_arrow.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  WithdrawApi,
  InitializeWithdrawApi,
  VerifyWithdrawOtpApi,
} from "../../apis/transaction/TransactionApi";

import Modal from "react-modal";
import Otp from "./otp/Otp";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

Modal.setAppElement("#root");
const DepositWithdraw = () => {
  const [deposit, setDeposit] = useState(true);
  const [showList, setShowList] = useState(false);
  const handleClick = () => {
    setShowList(!showList);
  };
  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      setShowList(false);
    }
  };

  const data = useSelector((state) => state.wallet.COINS);
  let navigate = useNavigate();

  // const data = [
  //   {
  //     src: BTC,
  //     name: "BTC",
  //     value: 0.0,
  //     holdings: 0.0,
  //   },
  //   {
  //     src: YLD,
  //     name: "YLD",
  //     value: 0.0,
  //     holdings: 0.0,
  //   },
  //   {
  //     src: ETH,
  //     name: "ETH",
  //     value: 0.0,
  //     holdings: 0.0,
  //   },
  //   {
  //     src: USDT,
  //     name: "USDT",
  //     value: 0.0,
  //     holdings: 0,
  //   },
  //   {
  //     src: USDC,
  //     name: "USDC",
  //     value: 0.0,
  //     holdings: 0,
  //   },
  // ];

  const [curr, setCurr] = useState({
    curr_src: data[0].logo,
    curr_name: data[0].symbol,
  });

  /* Withdraw section start*/

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const [withdraw, setWithdraw] = useState({
    curr_src: data[0].logo,
    curr_name: data[0].symbol,
    curr_id: data[0]._id,
  });

  const [withdrawAmount, setWithdrawAmount] = useState();

  const onWithdrawAmountChange = (e) => {
    const amount = e.target.value;
    setWithdrawAmount(amount);
  };

  const [withdrawAddress, setWithdrawAddress] = useState();

  const onWithdrawAddressChange = (e) => {
    const address = e.target.value;
    setWithdrawAddress(address);
  };

  const [showOtpPage, setShowOtpPage] = useState(false);

  function handleWithdraw(e) {
    e.preventDefault();
    if (!withdrawAmount) {
      setShowApiRes({
        message: "Please enter valid Amount",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (!withdrawAddress) {
      setShowApiRes({
        message: "Please enter Address",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else {
      InitializeApiFunction();
    }
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  async function InitializeApiFunction() {
    const result = await InitializeWithdrawApi();
    if (result.isSuccess) {
      // setShowOtpPage(true);
      // navigate("/otp");
      setModalIsOpen(true);
    }
  }

  const [transToken, setTransToken] = useState();
  const [callApi, setCallApi] = useState(false);

  useEffect(() => {
    if (callApi) {
      CallFinalApiFunction();
      // setCallApi(false);
    }
  }, [callApi]);

  async function CallFinalApiFunction() {
    const result = await WithdrawApi(
      {
        toAddress: withdrawAddress,
        value: withdrawAmount,
      },
      transToken,
      withdraw.curr_id
    );

    if (result.isSuccess) {
      toast.success("Transaction Done", {
        position: toast.POSITION.TOP_CENTER,
      });
      setWithdrawAddress("");
      setWithdrawAmount("");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }

    if (result.data.isSuccess) {
      setCallApi(false);
    } else {
      console.log("Error");
    }
  }

  /* Withdraw section end*/

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <div>
      <div className={`p-4 flex flex-col items-center `}>
        <div className=" w-full border-[1px] border-[#34CFFF] rounded-[20px] bg-[#E5F9FF] flex overflow-hidden mb-4">
          <div
            className={`p-4 rounded-r-[20px] w-[50%] text-center font-semibold cursor-pointer ${
              deposit && "bg-[#34CFFF] "
            }`}
            onClick={() => setDeposit(true)}
          >
            <p>Deposit</p>
          </div>
          <div
            className={`p-4 rounded-l-[20px] w-[50%] text-center font-semibold cursor-pointer ${
              !deposit && "bg-[#34CFFF] "
            }`}
            onClick={() => setDeposit(false)}
          >
            <p>Withdraw</p>
          </div>
        </div>

        {deposit ? (
          <div className="flex flex-col items-center w-full">
            <div
              onClick={handleClick}
              className="dropbtn w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between mb-4 cursor-pointer select-none"
            >
              <div className="flex items-center justify-center text-lg font-semibold dropbtn">
                <img
                  src={curr.curr_src}
                  alt="YLD"
                  className="dropbtn mr-2 h-[20px]"
                />
                {/* YLD */}
                {curr.curr_name}
              </div>
              <div className="flex items-center justify-center ">
                {!showList && (
                  <img src={DownArrow} alt="Down arrow" className="dropbtn" />
                )}
              </div>
            </div>

            {showList && (
              <div
                className="dropdown origin-top-right absolute mt-20 w-[80%] px-4 py-4 rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px_20px_#C0EFFF] opacity-100"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className="" role="none">
                  {data.map((val, key) => (
                    <div
                      key={key}
                      value={key}
                      className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid"
                      onMouseUp={() => {
                        setCurr({ curr_src: val.logo, curr_name: val.symbol });
                      }}
                    >
                      <div className="flex w-full px-4 py-4">
                        <div className="flex items-center justify-center text-lg font-semibold">
                          <img
                            src={val.logo}
                            alt={val.symbol}
                            className="mr-2 h-[20px]"
                          />
                          <p>{val.symbol}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="flex flex-col items-center w-[80%] mb-4">
              <Input placeholder="Amount" className="w-full" type="number" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center w-full">
            <div
              onClick={handleClick}
              className="dropbtn w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between mb-4 cursor-pointer select-none"
            >
              <div className="flex items-center justify-center text-lg font-semibold  dropbtn">
                <img
                  src={withdraw.curr_src}
                  alt="YLD"
                  className="dropbtn mr-2 h-[20px]"
                />
                {/* YLD */}
                {withdraw.curr_name}
              </div>
              <div className="flex items-center justify-center ">
                {!showList && (
                  <img src={DownArrow} alt="Down arrow" className="dropbtn" />
                )}
              </div>
            </div>

            {showList && (
              <div
                className="dropdown origin-top-right absolute mt-20 w-[80%] px-4 py-4 rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px_20px_#C0EFFF] opacity-100"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                // tabindex="-1"
              >
                <div className="" role="none">
                  {data.map((val, key) => (
                    <div
                      key={key}
                      value={key}
                      className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid"
                      onMouseUp={() => {
                        setWithdraw({
                          curr_src: val.logo,
                          curr_name: val.symbol,
                          curr_id: val._id,
                        });
                      }}
                    >
                      <div className="flex w-full px-4 py-4">
                        <div className="flex items-center justify-center text-lg font-semibold">
                          <img
                            src={val.logo}
                            alt={val.symbol}
                            className="mr-2 h-[20px]"
                          />
                          <p>{val.symbol}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <form
              onSubmit={handleWithdraw}
              className="flex flex-col items-center w-full"
            >
              <div className="flex flex-col items-center w-[80%] mb-4">
                <Input
                  placeholder="Amount"
                  className="w-full"
                  type="number"
                  step="any"
                  onChange={onWithdrawAmountChange}
                  value={withdrawAmount}
                />
              </div>
              <div className="mb-4 w-[80%]">
                <p className="text-right text-[#1D288A] font-semibold text-md">
                  Withdrawal Fee: 20 YLD
                </p>
              </div>
              <div className="flex flex-col items-center w-[80%] mb-4">
                <Input
                  placeholder="Recipient's address"
                  className="w-full"
                  onChange={onWithdrawAddressChange}
                  value={withdrawAddress}
                />
              </div>
              <div className="">
                {showApiRes.visible && (
                  <div className="">
                    <MessageBox
                      width="100%"
                      variant={showApiRes.variant}
                      message={showApiRes.message}
                      title={showApiRes.title}
                    ></MessageBox>
                  </div>
                )}
              </div>
              <div className="flex justify-center py-2 w-[50%]">
                <Button className="w-full" type="submit">
                  {" "}
                  Continue
                </Button>
              </div>
            </form>
            <Modal
              isOpen={modalIsOpen}
              className="flex items-center justify-center w-full h-full"
            >
              <Otp
                setModalIsOpen={setModalIsOpen}
                setCallApi={setCallApi}
                setTransToken={setTransToken}
              />
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default DepositWithdraw;
