import authHeader from "../../redux/services/auth-header";
import { handleApiCall } from "../apis";

export const WalletApi = async () => {
  let header = await authHeader();
  const val = await handleApiCall("get", "/get-assets", {}, true, header).then(
    (res) => {
      return res;
    }
  );
  return val;
};

export const TokenApi = async () => {
  let header = await authHeader();
  const val = await handleApiCall("get", "/get-tokens", {}, true, header).then(
    (res) => {
      return res;
    }
  );
  return val;
};

export const HoldingsApi = async () => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/get-holdings`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};
