// import Persona from "persona";
import React from "react";
import { CompleteKYCApi } from "../../apis/profile/getUserProfile";

const Persona = require("persona");
function InlineInquiry() {
  // async function handleComplete(props){
  //   console.log(props.inquiryId,props.status,props.fields)
  // }
  const client = new Persona.Client({
    templateId: "itmpl_XikGucpXE2J8KhaHZUar9moc",
    // templateId: "itmpl_MMrYAzyBG7oT5n6qc3quw6wN",
    environment: "sandbox",
    onReady: () => client.open(),
    onComplete: async ({ inquiryId, status, fields }) => {
      // console.log(inquiryId, status, fields)
      // console.log(typeof status);
      // console.log(typeof inquiryId);
      // console.log(typeof fields);
      const result = await CompleteKYCApi({
        inquiryId: inquiryId, 
        status: true, 
        kycFields: fields,
      })
      // console.log(result);
      // handleComplete( inquiryId, status, fields )
    },
    onCancel: ({ inquiryId, sessionToken, status }) => console.log(inquiryId, sessionToken, status),
    onError: (error) => console.log(error),
  });
  return client;
}

export default InlineInquiry;
