import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  // setIsVerified,
  setAccessToken,
  setRefreshToken,
  setUserId,
} from "../../redux/actions/auth";
import { useNavigate } from "react-router-dom";
import LoginImage from "../../assets/images/loginImage.svg";
import Logo from "../../assets/images/logo.svg";
import Input from "../../components/styledComponents/Input/Input.js";
import Button from "../../components/styledComponents/Buttons/Button";
import { SignupApi, SignupGoogleApi } from "../../apis/auth/AuthApis";
import GoogleLogin from "react-google-login";
import google from "../../assets/images/google.svg";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";
import { set2FA } from "../../redux/actions/2fa";
import { store } from "../../redux/store/store";

function Register(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referral, setReferral] = useState("");
  const [dob, setDob] = useState("");
  const [username, setUsername] = useState("");

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function handleRegister(e) {
    e.preventDefault();
    if (email.length === 0) {
      setShowApiRes({
        message: "Please enter valid email",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (name.length === 0) {
      setShowApiRes({
        message: "Please enter valid name",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (username.length === 0) {
      setShowApiRes({
        message: "Please enter valid username",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (password.length < 8) {
      setShowApiRes({
        message: "Password must be at least 8 characters long",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (!validateEmail(email)) {
      setShowApiRes({
        message: "Please enter valid email",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else {
      SignupApiFunction();
    }
  }

  const dispatch = useDispatch();
  let navigate = useNavigate();

  async function SignupApiFunction() {
    const result = await SignupApi({
      name: name,
      email: email,
      password: password,
      username: username,
      dateOfBirth: dob,
      isGame: false,
      referredFrom: referral,
    });
    if (result.isSuccess) {
      localStorage.setItem("email", email);
      // setIsVerified(result.data.isSuccess, dispatch);
      navigate("/email-verify");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    const token = store.getState().auth.access_token;
    if (token) {
      navigate("/dashboard");
    }
  });

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <div className="grid grid-cols-9 divide-x place-content-center">
      <div className="hidden md:col-span-4  lg:col-span-5 xl:col-span-6 md:flex md:justify-center md:items-center bg-[#F4F7FA] px-10 ">
        <img src={LoginImage} alt="Loginimage" className="onboarding-bg" />
      </div>

      <div className="col-span-9 md:col-span-5 lg:col-span-4 xl:col-span-3 border-none py-4  sm:py-10 px-8  md:px-12 sm:px-24 h-[100vh] overflow-y-auto">
        <div className="pt-0 pb-4 sm:pt-2 sm:pb-4">
          <img src={Logo} alt="Logo" className="max-w-[45%] md:max-w-full" />
        </div>
        <div className="flex flex-col justify-center mt-8 sm:mt-0">
          <div className="mt-8 mb-0 text-4xl font-semibold sm:text-4xl sm:mt-4 sm:mb-4">
            Create An Account
          </div>
          <div className="my-4 font-semibold text-md sm:text-lg">
            Welcome to the yield yeti app. Create a new account by entering
            below details.
          </div>

          <form onSubmit={handleRegister}>
            <div className="py-2">
              <div className="">
                <Input
                  placeholder="Your Name"
                  type="text"
                  className="w-full"
                  value={name}
                  onChange={onChangeName}
                />
              </div>
              <div className="pt-4">
                <Input
                  placeholder="Username"
                  type="text"
                  className="w-full"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
              <div className="pt-4">
                <Input
                  placeholder="Email address"
                  type="email"
                  className="w-full"
                  value={email}
                  onChange={onChangeEmail}
                />
              </div>
              <div className="pt-4">
                <Input
                  placeholder="Password"
                  type="password"
                  className="w-full"
                  value={password}
                  onChange={onChangePassword}
                />
              </div>
              <div className="pt-4">
                <Input
                  placeholder="Date Of Birth"
                  type="date"
                  className="w-full"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
              <div className="pt-4">
                <Input
                  placeholder="Referral code"
                  type="text"
                  className="w-full"
                  value={referral}
                  onChange={(e) => {
                    setReferral(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="">
              {showApiRes.visible && (
                <div className="pt-2 pl-2">
                  <MessageBox
                    width="100%"
                    variant={showApiRes.variant}
                    message={showApiRes.message}
                    title={showApiRes.title}
                  ></MessageBox>
                </div>
              )}
            </div>
            <div className="font-semibold text-sm sm:text-lg mt-4 text-[#C0CBCE]">
              <p>
                {" "}
                By continuing, you agree to YieldYeti App's&nbsp;
                <a
                  className="text-[#872BFD] underline"
                  href="https://yieldyeti.com/terms-and-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  className="text-[#872BFD] underline"
                  href="https://yieldyeti.com/privacy-policy-2/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="pt-4">
              <Button className="w-full" type="submit">
                Sign Me Up
              </Button>
            </div>
          </form>

          {/* <div className="grid content-center grid-cols-11 pt-2">
            <div className="col-span-5 mt-3">
              <hr />
            </div>
            <p className="col-span-1 font-semibold text-center">OR</p>
            <div className="col-span-5 mt-3">
              <hr />
            </div>
          </div> */}
          {/* <div className="pt-2">
            <GoogleLogin
              clientId={process.env.REACT_APP_SIGNUP_GOOGLE}
              render={(renderProps) => (
                <button
                  className="w-full flex items-center justify-center rounded-[15px] px-[14px] py-[12px] bg-[#e5f9ff] border border-[#34cfff] font-semibold"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img
                    src={google}
                    alt="google"
                    className="h-[30px] w-[30px] mr-2"
                  />
                  Sign Up With Google
                </button>
              )}
              onSuccess={responseGoogle}
              // onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </div> */}
          <div className="flex justify-center py-2 mt-4 font-semibold sm:mt-0">
            <p>
              Already have an account?{" "}
              <a className="text-[#872BFD]" href="/">
                Login here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
