export const SET_ISVERIFIED = "SET_ISVERIFIED";
export const SET_ACCESSTOKEN = "SET_ACCESSTOKEN";
export const SET_REFRESHTOKEN = "SET_REFRESHTOKEN";
export const SET_USERID = "SET_USERID";

export const SET_USER = "SET_USER";

export const SET_2FA = "SET_2FA";

export const SET_WALLET = "SET_WALLET";
export const SET_COINS = "SET_COINS";
export const SET_HOLDINGS = "SET_HOLDINGS"

export const SET_PLANS = "SET_PLANS"