import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import tfa from "./2fa";
import wallet from "./wallet"
import plans from "./plans"

export default combineReducers({
    auth,
    user,
    tfa,
    wallet,
    plans,
  });