import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import HistoryTable from "./HistoryTable";
import Available from "./AssetAvailable";
import { useParams } from "react-router-dom";

function Asset() {
  let { id } = useParams();

  return (
    <Layout>
      <div className="flex md:flex-row flex-col gap-2 mx-0 lg:mx-10 max-h-full">
        <div className="bg-white px-4 lg:px-6 py-2 rounded-2xl w-full md:w-2/3 max-h-full">
          <p className="font-bold text-3xl py-4 color-[#002C3A]">Wallet</p>
          <div className="text-lg opacity-70">
            <a href="/dashboard" className="font-semibold text-[#00C3FF] ">
              Dashboard {" > "}
            </a>
            <a href="/wallet" className="font-semibold text-[#00C3FF] ">
              Wallet {" > "}
            </a>
            <span className="font-semibold text-[#000072]">Assets</span>
          </div>

          <div className="py-8 px-2">
            <HistoryTable 
              id={id}
            />
          {/* <div className="py-8">
            <HistoryTable /> */}
          </div>
        </div>
        <div className="bg-white rounded-2xl w-full md:w-1/3 h-max">
          <Available />
        </div>
      </div>
    </Layout>
  );
}

export default Asset;
