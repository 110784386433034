import React from "react";
import Layout from "../../components/layout/Layout";
import CoinTable from "../wallet/CoinTable";
import RewardsTransactions from "./RewardsTransactions";
import Available from "./Available";
import Invest from "./Invest";
import Progress from "./Progress";
import Levels from "./Levels";
import Button from "../../components/styledComponents/Buttons/Button";
import Chart from "../dashboard/chart/chart";
import { Link } from "react-router-dom";

function Portfolio() {
  return (
    <Layout>
      <div className="flex  md:flex-row flex-col gap-2 mx-0 lg:mx-10 max-h-full">
        <div className="w-full md:w-2/3 max-h-full flex flex-col gap-3">
          <div className="bg-white px-4 lg:px-6 py-2 rounded-2xl">
            <p className="font-bold text-3xl py-4 color-[#002C3A]">Portfolio</p>
            <div className="">
              <CoinTable />
            </div>
            <div>
              <Chart />
            </div>
          </div>
          <div className="bg-white rounded-2xl max-h-full">
            <RewardsTransactions />
          </div>
        </div>
        <div className="flex flex-col gap-3 w-full md:w-1/3 max-h-full">
          <div>
            <Available />
          </div>
          <div>
            <Invest />
          </div>
          <div>
            <Progress />
          </div>
          <div>
            <Levels />
          </div>
          <div className="py-2">
            <Link to="/invest">
              <Button className="w-full"> % SWAP YLD</Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Portfolio;
