import React, { useEffect, useState } from "react";
import TfaImage from "../../assets/images/two_fa.svg";
// import QrCode from "../../assets/images/tfa_qr_code.png";
import copy from "copy-to-clipboard";
import Input from "../../components/styledComponents/Input/Input";
import CopyCheckmarkButton from "../../components/styledComponents/Buttons/CopyCheckmarkButton";
import { QRApi, Api2FA } from "../../apis/2fa/TfaApi";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";
import { useSelector } from "react-redux";
import { set2FA } from "../../redux/actions/2fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SettingsSecurityTfa = () => {
  /*QR code and secret key */

  const [qr, setQr] = useState("");
  const [qrValue, setQrValue] = useState("");

  const getQRFunction = async () => {
    const result = await QRApi();
    if (result === false) {
      console.log("Error");
    } else {
      setQr(result.data.qrData);
      setQrValue(result.data.Secret);
    }
  };

  // const copyToClipboard = (e) =>
  const [flag, setFlag] = useState(false);
  async function copyToClipboard(e) {
    e.preventDefault();
    toast.success("Copied to Clipboard", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
    copy(qrValue);
    setFlag(true);
    setTimeout(() => {
      setFlag(false);
    }, 3000);
  }

  useEffect(() => {
    getQRFunction();
  }, []);

  /*End QR code and secret key */

  /* Enable/Diable 2FA */

  const [otp, setOtp] = useState();
  // const [enable, setEnable] = useState(false);
  const enable = useSelector((state) => state.tfa.is2FA);

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const onChangeOtp = (e) => {
    e.preventDefault();
    const otp = e.target.value;
    setOtp(otp);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (otp.length !== 6) {
      console.log("err");
      setShowApiRes({
        message: "OTP must be of 6 Digits",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else {
      Change2FAStatusFunction();
    }
  }

  const dispatch = useDispatch();

  async function Change2FAStatusFunction() {
    const result = await Api2FA({
      otp: otp,
      // flag: !enable,
      flag: !enable,
    });
    if (result.isSuccess) {
      setOtp("");
      // setEnable(!enable);
      const temp = !enable;
      set2FA(temp, dispatch);
      // navigate("../password-reset-success");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <div>
      <div className="flex flex-col items-center justify-center sm:flex-row">
        <div
          className={` ${
            enable
              ? "w-[90%] sm:w-[75%] pt-4 flex flex-col items-center justify-center mx-4"
              : "pt-4 sm:max-w-[60%] flex flex-col items-center justify-center mx-4"
          }`}
        >
          <div className="mt-4 mb-8 max-w-[40%] w-full">
            <img src={TfaImage} alt="" />
          </div>
          <div className={`mb-4 text-center`}>
            <h1 className="text-[#002C3A] font-semibold text-xl sm:text-3xl">
              TWO-FACTOR AUTHENTICATION
            </h1>
          </div>
          <div className="px-4 mb-6 text-center sm:px-6">
            <p className="text-[#959BC9] font-semibold text-md sm:text-lg">
              2FA is an extra security layer for your account that requires you
              to enter a passcode to log in or withdraw funds. To enable it,
              please install Google Authenticator or Authy on your smartphone
              and scan or copy the QR code on the following page. Don't forget
              to save your recovery key in the process. You will then be asked
              to confirm the 6-digit code from the authentication app.
            </p>
          </div>
          <form
            className="flex flex-col items-center w-full"
            onSubmit={handleSubmit}
          >
            <div className="mb-4 w-[80%] sm:w-[60%]">
              <Input
                placeholder="Your 2FA passcode"
                className="w-full text-center"
                type="number"
                onChange={onChangeOtp}
                value={otp}
              />
            </div>
            <div className="">
              {showApiRes.visible && (
                <div className="">
                  <MessageBox
                    width="100%"
                    variant={showApiRes.variant}
                    message={showApiRes.message}
                    title={showApiRes.title}
                  ></MessageBox>
                </div>
              )}
            </div>
            {enable ? (
              <div className="mb-4 ">
                <CopyCheckmarkButton width="500px">Disable</CopyCheckmarkButton>
              </div>
            ) : (
              <div className="mb-4 ">
                <CopyCheckmarkButton logo="check" width="500px">
                  Enable
                </CopyCheckmarkButton>
              </div>
            )}
            {/* <div className="mb-4 ">
              <CopyCheckmarkButton logo="check">Enable</CopyCheckmarkButton>
            </div> */}
          </form>
        </div>
        {!enable && (
          <div className="h-full w-[90%] sm:w-[40%] py-4">
            <div className="border-[1px]  border-[#C4C4C4] rounded-[20px] h-full w-full">
              <div className="border-[#C4C4C4] border-b-[1px] p-4 sm:p-6">
                <h1 className="text-lg font-semibold sm:text-2xl">
                  {" "}
                  Enable 2FA
                </h1>
              </div>
              <div className="flex flex-col items-center justify-center w-full px-6 pt-4 pb-10">
                <div className="flex flex-col items-center justify-center max-w-full mb-4">
                  <div className="p-4 bg-[#E5F9FF] rounded-[20px] max-w-[60%] mb-4">
                    <img src={qr} alt="" className="" />
                  </div>
                  <div className="mb-4 text-center sm:mb-8">
                    <p className="font-semibold text-md sm:text-lg font-[#002C3A] break-all ">
                      {qrValue}
                      {/* MM2XKUDOKM7CK4LMIJATUWZMHNBEKSTCORRWQ3D2JN2XGRRZKRJA */}
                    </p>
                  </div>
                </div>
                <div
                  className={`w-full ${
                    !flag ? "" : "mb-0 relative"
                  } flex flex-col items-center justify-center`}
                >
                  <div onClick={copyToClipboard}>
                    <CopyCheckmarkButton logo="copy" variant="outline">
                      Copy Security Key
                    </CopyCheckmarkButton>
                  </div>
                  {/* {flag && (
                    <div className="absolute mt-[85px]">
                      <p className="font-semibold">Copied</p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* </div>
        </div>
      </SettingsLayout> */}
    </div>
  );
};

export default SettingsSecurityTfa;
