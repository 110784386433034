import React, { useState, useEffect } from "react";
import ForgotPasswordImage from "../../assets/images/loginImage.svg";
import Logo from "../../assets/images/logo.svg";
import Input from "../../components/styledComponents/Input/Input.js";
import Button from "../../components/styledComponents/Buttons/Button";
import { OTPVerificationApi } from "../../apis/auth/AuthApis";
import { useNavigate } from "react-router-dom";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";
import { useDispatch } from "react-redux";
import {
  // setIsVerified,
  setAccessToken,
  setRefreshToken,
} from "../../redux/actions/auth";

function OTP(props) {
  const [otp, setOtp] = useState("");

  const onChangeOtp = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  function handleForgot(e) {
    e.preventDefault();
    if (otp.length !== 6) {
      setShowApiRes({
        message: "Please enter valid otp",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else {
      OTPVerifyFunction();
    }
  }

  const dispatch = useDispatch();
  let navigate = useNavigate();

  async function OTPVerifyFunction() {
    const result = await OTPVerificationApi({
      otp: otp,
    });
    if (result.isSuccess) {
      setRefreshToken(result.data.response.refreshToken, dispatch);
      setAccessToken(result.data.response.accessToken, dispatch);
      // localStorage.setItem("token", result.data.response.accessToken);
      // localStorage.setItem(
      //   "refreshToken",
      //   result.data.response.refreshToken
      // );
      navigate("/dashboard");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <div className="grid grid-cols-9 divide-x place-content-center">
      <div className="hidden md:col-span-4  lg:col-span-5 xl:col-span-6 bg-[#F4F7FA] md:flex md:justify-center md:items-center px-10">
        <img
          src={ForgotPasswordImage}
          alt="ForgotPassword"
          className="onboarding-bg"
        />
      </div>

      <div className="col-span-9 px-8 py-4 border-none md:col-span-5 lg:col-span-4 xl:col-span-3 sm:py-10 md:px-12 sm:px-24">
        <div className="py-2">
          <img src={Logo} alt="logo" />
        </div>
        <div className="py-4 mt-6 text-4xl font-semibold">
          Please Enter Code
        </div>

        <form onSubmit={handleForgot} className="w-full">
          <div className="pt-8">
            <Input
              placeholder="Code"
              type="number"
              className="w-full"
              value={otp}
              onChange={onChangeOtp}
            />
          </div>
          <div className="">
            {showApiRes.visible && (
              <div className="pt-4 pl-2">
                <MessageBox
                  width="100%"
                  variant={showApiRes.variant}
                  message={showApiRes.message}
                  title={showApiRes.title}
                ></MessageBox>
              </div>
            )}
          </div>
          <div className="w-full pt-4">
            <Button className="w-full" type="submit">
              Verify otp
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OTP;
