import {
  SET_ISVERIFIED,
  SET_ACCESSTOKEN,
  SET_REFRESHTOKEN,
  SET_USERID,
} from "./type";

export const setAccessToken = (value, dispatch) =>
  dispatch({ type: SET_ACCESSTOKEN, payload: value });
export const setRefreshToken = (value, dispatch) =>
  dispatch({ type: SET_REFRESHTOKEN, payload: value });
export const setIsVerified = (value, dispatch) =>
  dispatch({ type: SET_ISVERIFIED, payload: value });
export const setUserId = (value, dispatch) =>
  dispatch({ type: SET_USERID, payload: value });
