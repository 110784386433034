import {
    SET_ISVERIFIED,
    SET_ACCESSTOKEN,
    SET_REFRESHTOKEN,
    SET_USERID,
} from "../actions/type";

const initialState = {};

export default function foo(state=initialState, action){
    const {type,payload} = action;

    switch(type){
        case SET_ISVERIFIED:
        return {
            ...state,
            isVerified: payload
        }
        case SET_ACCESSTOKEN:
        return {
            ...state, 
            access_token: payload
        }
        case SET_REFRESHTOKEN:
            return {
            ...state, 
            refresh_token: payload
        }
        case SET_USERID:
            return {
            ...state, 
            user_id: payload
        }
        default:
            return state;
    }
}