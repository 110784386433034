import React from "react";
import BTC from "../../../assets/images/btc.svg";
import ETH from "../../../assets/images/eth.svg";
import USDC from "../../../assets/images/usdc.svg";
import YLD from "../../../assets/images/yld.svg";

function Level1() {
  const data = [
    {
      src: BTC,
      percentage: "3",
      name: "BTC",
    },
    {
      src: ETH,
      percentage: "50",
      name: "ETH",
    },
    {
      src: USDC,
      percentage: "100,000",
      name: "USDC",
    },
    {
      src: YLD,
      percentage: "100,000",
      name: "YLD",
    },
  ];

  return (
    <div className="border rounded-2xl border-[#959BC9] bg-[#FFFFFF] overflow-hidden">
      <div className="border-b border-r border-l overflow-hidden rounded-b-2xl border-[#959BC9] bg-[#E5F9FF]">
        <p className="text-md lg:text-lg font-semibold text-[#00C3FF] px-4 py-1">
          Level 1
        </p>
      </div>
      <div className="flex flex-row lg:gap-2 justify-around py-4 overflow-x-auto">
        {data.map((val, key) => {
          return (
            <div
              key={key}
              value={key}
              className="flex flex-col justify-center items-center lg:px-2 py-2"
            >
              <img src={val.src} alt="name" className="w-[40px] h-[40px] md:w-[25px] md:h-[25px] lg:w-[40px] lg:h-[40px]" />
              <div className="text-md lg:text-lg font-semibold text-[#959BC9] text-center py-2">
                <p>{val.percentage}</p>
                <p>{val.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Level1;
