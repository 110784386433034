import React, { useEffect, useState, useRef } from "react";
import Layout from "../../components/layout/Layout";
import Table from "./table/Table";
import SwapYLD from "./swapyld/SwapYLD";
import InvestPlan from "./investPlan/InvestPlan";
import Invest from "../../assets/images/invest_image.svg";
import { GetPlansApi } from "../../apis/invest/InvestApi";
// import "./swap.css";
import { useSelector } from "react-redux";
import Animation from "../../assets/images/animation.svg";
import Video from "../../assets/video/yeti.mov";

function Swap() {
  const plans = useSelector((state) => state.plans.plans);

  const [speed, setSpeed] = useState(1);

  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = speed;
  };

  useEffect(() => {
    setPlayBack();
  }, [speed]);

  return (
    <Layout>
      <div className="flex max-h-full mx-0 lg:mx-10">
        <div className="bg-white px-4 py-2 rounded-2xl max-h-full w-full md:w-[50%]">
          <p className="font-bold text-3xl py-4 color-[#002C3A]">Invest</p>
          <div className="text-lg opacity-70">
            <a href="/dashboard" className="font-semibold text-[#00C3FF] ">
              Dashboard {" > "}
            </a>
            <span className="font-semibold text-[#000072]">Invest</span>
          </div>
          <p className="font-semibold text-2xl md:text-3xl pt-6 pb-2 color-[#002C3A]">
            Investment Plans
          </p>
          <div className="py-2">
            {plans &&
              plans.map((val, key) => (
                <div
                  className={`pb-4 ${val.risk}`}
                  key={key}
                  onMouseOver={() => {
                    if (val.risk == "LOW") {
                      setSpeed(0.5);
                    } else if (val.risk == "HIGH") {
                      setSpeed(5);
                    } else {
                      setSpeed(2);
                    }
                  }}
                  onMouseLeave={() => {
                    setSpeed(1);
                  }}
                >
                  <InvestPlan
                    key={key}
                    value={key}
                    risk={val.risk}
                    Fix_APY={val.fixedApy}
                    Reward_APY={val.rewardsApy}
                    Max={val.totalInvestment}
                    Id={val._id}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className=" md:flex justify-center items-start hidden w-[50%]">
          {/* <img src={Invest} alt="Animation" /> */}
          <video
            className="sticky top-[30%] md:flex hidden"
            width="70%"
            autoPlay
            muted
            loop
            ref={videoRef}
            // onCanPlay={() => setPlayBack()}
          >
            <source className="myVideo" src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Layout>
  );
}

export default Swap;
