import React, { useState } from "react";
import CheckButton from "../styledComponents/Buttons/CheckButton";

function Footer() {
  //   const widthOutput = document.querySelector("#width");

  const [width, setWidth] = useState(window.screen.width);
  function reportWindowSize() {
    setWidth(window.screen.width);
  }

  window.onresize = reportWindowSize;
  return (
    <div className="flex justify-between py-2 md:py-4 md:px-20 px-2 items-center w-full max-h-full">
      <div>
        {width > 768 ? (
          <p className=" font-semibold text-[#1D288A] opacity-50">
            © All Rights are Reserved @ YieldYeti.app
          </p>
        ) : (
          <>
            <p className=" font-semibold text-[#1D288A] opacity-50">
              © All Rights are Reserved
            </p>{" "}
            <p className=" font-semibold text-[#1D288A] opacity-50">
              {" "}
              @ YieldYeti.app
            </p>
          </>
        )}
      </div>
      <div className="md:flex md:flex-row flex-col gap-2 md:gap-6 items-center">
        <p className="text-center font-semibold text-[#1D288A] opacity-50">
          V&nbsp;1.0.0
        </p>
        <CheckButton logo="help">Help</CheckButton>
      </div>
    </div>
  );
}

export default Footer;
