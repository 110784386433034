import React from "react";
import SettingsLayout from "./SettingsLayout";
import { useState } from "react";
import SettingsSecurityChangePassword from "./SettingsSecurityChangePassword";
import SettingsSecurityTfa from "./SettingsSecurityTfa";
const SettingsSecurityHome = () => {
  const [tfa, setTfa] = useState(true);

  return (
    <div>
      <SettingsLayout>
        <div className="flex md:flex-row flex-col max-h-full ">
          <div className="basis-1/5 max-w-full max-h-full border-r-[#C4C4C4] border-b-[#C4C4C4] border-b-[1px] md:border-b-[0px] md:border-r-[1px]">
            <div
              className={`cursor-pointer  mb-2  ${
                tfa
                  ? "bg-[#E5F9FF]/60 border-[#74D5F3] border-opacity-60 border-[1px]"
                  : "border-[1px] border-[#fff]"
              }`}
            >
              <div className="p-4 sm:p-6" onClick={() => setTfa(true)}>
                <p
                  className={`text-[#1D288A] font-semibold text-lg sm:text-xl ${
                    !tfa && "opacity-50"
                  }`}
                >
                  Enable 2FA
                </p>
              </div>
            </div>
            <div
              className={`cursor-pointer md:mb-2 ${
                !tfa
                  ? "bg-[#E5F9FF]/60 border-[#74D5F3] border-opacity-60 border-[1px]"
                  : "border-[1px] border-[#fff]"
              }`}
            >
              <div className="p-4 sm:p-6" onClick={() => setTfa(false)}>
                <p
                  className={`text-[#1D288A] font-semibold text-lg sm:text-xl ${
                    tfa && "opacity-50"
                  }`}
                >
                  Change Password
                </p>
              </div>
            </div>
          </div>

          <div className="basis-4/5 py-2 sm:pr-6 h-full flex">
            {tfa ? <SettingsSecurityTfa /> : <SettingsSecurityChangePassword />}
          </div>
        </div>
      </SettingsLayout>
    </div>
  );
};

export default SettingsSecurityHome;
