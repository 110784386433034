import React, { useState, useEffect } from "react";
import Button from "../../../components/styledComponents/Buttons/Button";
import Input from "../../../components/styledComponents/Input/Input";
import MessageBox from "../../../components/styledComponents/MessageBox/MessageBox";
import Logo from "../../../assets/images/logo.svg";
import { VerifyWithdrawOtpApi } from "../../../apis/transaction/TransactionApi";
import { useNavigate } from "react-router-dom";

function Otp({ setModalIsOpen, setCallApi, setTransToken }) {
  const [emailOtp, setEmailOtp] = useState("");
  const [googleOtp, setGoogleOtp] = useState("");

  let navigate = useNavigate();

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const onEmailOtp = (e) => {
    const emailOtp = e.target.value;
    setEmailOtp(emailOtp);
  };

  const onGoogleOtp = (e) => {
    const googleOtp = e.target.value;
    setGoogleOtp(googleOtp);
  };

  function handleSubmit(e) {
    e.preventDefault();
    VerifyOtpFunction();
  }

  async function VerifyOtpFunction() {
    const result = await VerifyWithdrawOtpApi({
      emailOtp: parseInt(emailOtp),
      googleOtp: parseInt(googleOtp),
    });
    if (result.isSuccess) {
      setTransToken(result.data.TransactionToken);
      //CallApi();
      setCallApi(true);
      setModalIsOpen(false);
      // navigate(-1);
      //   navigate("../password-reset-success");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }
  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <div className="bg-white  px-12 py-6 md:w-[40%] w-[90%] h-[60%] border-2 rounded-2xl">
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between py-2">
          <img src={Logo} alt="logo" className="w-[60%] md:w-auto" />
          <div
            className="text-lg font-bold cursor-pointer"
            onClick={() => {
              setModalIsOpen(false);
            }}
          >
            {" "}
            X{" "}
          </div>
        </div>
        <div className="py-4 mt-4 text-3xl font-semibold">
          OTP for Confirmation
        </div>
        <div className="pt-6">
          <div className="text-xl font-semibold">Email OTP:</div>
          <Input
            placeholder="Enter OTP"
            type="password"
            className="w-full"
            value={emailOtp}
            onChange={onEmailOtp}
          />
          <div className="pt-4 text-xl font-semibold">2FA Code:</div>
          <Input
            placeholder="Enter code"
            type="password"
            className="w-full"
            value={googleOtp}
            onChange={onGoogleOtp}
          />
        </div>
        <div className="">
          {showApiRes.visible && (
            <div className="px-2 ">
              <MessageBox
                width="100%"
                variant={showApiRes.variant}
                message={showApiRes.message}
                title={showApiRes.title}
              ></MessageBox>
            </div>
          )}
        </div>
        <div className="w-full pt-4">
          <Button className="w-full" type="submit">
            Verify
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Otp;
