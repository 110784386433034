import authHeader from "../../redux/services/auth-header";
import { handleApiCall } from "../apis";

export const WithdrawApi = async (data, transaction, tokenId) => {
  let temp = await authHeader();
  temp["transaction"] = "Bearer " + transaction;
  const val = await handleApiCall(
    "post",
    `/withdraw-tokens/${tokenId}`,
    data,
    true,
    temp
  ).then((res) => {
    return res;
  });
  return val;
};

export const InitializeWithdrawApi = async () => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    "/initialize-transaction",
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const VerifyWithdrawOtpApi = async (data) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "post",
    "/verify-otp-transaction",
    data,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const TransactionApi = async () => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    "/get-transactions",
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const TokenTransactionApi = async (token) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/get-transactions/${token}`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};
