import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import EmailVerify from "../pages/auth/EmailVerify";
import PasswordResetSuccess from "../pages/auth/PasswordResetSuccess";
import EmailVerifySuccess from "../pages/auth/EmailVerifySuccess";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import OTP from "../pages/auth/2FA";
import SettingsHome from "../pages/settings/SettingsHome";

import SettingsProfile from "../pages/settings/SettingsProfile";
import SettingsHelp from "../pages/settings/SettingsHelp";
import SettingsLegalHome from "../pages/settings/SettingsLegalHome";
import SettingsSecurityHome from "../pages/settings/SettingsSecurityHome";
import SettingsVerification from "../pages/settings/SettingsVerification";
import Otp from "../pages/wallet/otp/Otp";

import Dashboard from "../pages/dashboard/Dashboard";
import Portfolio from "../pages/portfolio/Portfolio";
import Fiat from "../pages/fiat/Fiat";
import Swap from "../pages/swap/Swap";
import Asset from "../pages/wallet/Asset";
import Wallet from "../pages/wallet/Wallet";

function PublicRoute() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/2fa" element={<OTP />} />
          <Route
            // exact
            path="/reset-password/:id"
            element={<ResetPassword />}
          />
          <Route path="/email-verify" element={<EmailVerify />} />
          <Route
            // exact
            path="/email-verify-success/:id"
            element={<EmailVerifySuccess />}
          />
          <Route
            path="/password-reset-success"
            element={<PasswordResetSuccess />}
          />


          <Route path="/settings" element={<SettingsHome />} />
          {/* <Route path="/settings/*" element={<SettingsProfile/>} /> */}
          <Route path="/settings/profile" element={<SettingsProfile />} />
          <Route path="/settings/help-and-support" element={<SettingsHelp />} />
          <Route path="/settings/legal" element={<SettingsLegalHome />} />
          <Route path="/otp" element={<Otp />} />

          <Route path="/settings/security" element={<SettingsSecurityHome />} />
          <Route
            path="/settings/verification"
            element={<SettingsVerification />}
          />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/fiat" element={<Fiat />} />
          <Route path="/invest" element={<Swap />} />
          <Route path="/wallet/asset/:id" element={<Asset />} />
          <Route path="/wallet" element={<Wallet />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default PublicRoute;