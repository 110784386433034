import React, { useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import {store} from '../../redux/store/store'

function Layout(props) {
  let navigate = useNavigate();
  
  useEffect(() => {
    // getData();
    const token = store.getState().auth.access_token;
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <div className="h-screen flex flex-col justify-between">
      <Header />
      <div className="flex-1 bg-[#F4F7FA] p-4">{props.children}</div>
      <Footer />
    </div>
  );
}

export default Layout;
