import {
  RefreshAccessTokenApi,
  AccessTokenApi,
} from "../../apis/auth/AuthApis";
import { store } from "./../store/store";
import { SET_ACCESSTOKEN, SET_REFRESHTOKEN } from "../actions/type";

const accessTokenCheck = async () => {
  const result = await AccessTokenApi();
  let res = refreshTokenCheck(result.data.isValid);
  return res;
};

const refreshTokenCheck = async (valid) => {
  if (!valid) {
    const result = await RefreshAccessTokenApi();
    if (!result) {
      store.dispatch({ type: SET_ACCESSTOKEN, payload: "" });
      store.dispatch({ type: SET_REFRESHTOKEN, payload: "" });
      return false;
    } else {
      store.dispatch({
        type: SET_ACCESSTOKEN,
        payload: result.data.response.accessToken,
      });
      return true;
    }
  } else {
    return true;
  }
};

const authHeader = async () => {
  // const token = localStorage.getItem("token");
  const goAhead = accessTokenCheck();
  return goAhead.then(res => {
    const token = store.getState().auth.access_token;
    if (res) {
      return { Authorization: "Bearer " + token, };
    } else {
      window.location.href = "/";
      return false;
    }
  })
  // return {
  //   Authorization: `Bearer ${token}`,
  // };
};

export default authHeader;
