import React from "react";
import Button from "../../components/styledComponents/Buttons/Button";
import Logo from "../../assets/images/logo.svg";
import PasswordResetSuccessImg from "../../assets/images/password_reset_succesful.svg";
import { Link } from "react-router-dom";
const PasswordResetSuccess = () => {
  return (
    <div className="bg-lightgrey sm:pt-10 h-screen flex flex-col sm:items-center sm:justify-center max-w-screen overflow-hidden">
      <div className="pt-0 pb-4 sm:hidden block w-[35%] my-6 mx-8">
        <img src={Logo} className="" alt="logo" />
      </div>
      <div className="sm:max-w-[75%] w-full flex-col items-center h-full justify-center flex">
        <div className="mb-10 sm:mb-20 sm:w-[95%] md:w-[85%] lg:w-[75%] w-[125%] ">
          <img
            src={PasswordResetSuccessImg}
            alt="Password reset success"
            className="w-full"
          />
        </div>
        <div className="max-w-full flex flex-col items-center ">
          {/* <div className="mb-10 flex flex-col justify-center items-center"> */}
          <div className="mb-4 sm:max-w-full text-center">
            <h2 className="font-sans sm:text-4xl lg:text-5xl text-3xl sm:font-extrabold font-semibold text-center ">
              Password Reset Successful
            </h2>
          </div>
          <div className="sm:max-w-[75%] w-full flex flex-col items-center ">
            <div className="mb-8 text-center max-w-[90%]">
              <h3 className="font-sans sm:text-xl text-lg font-semibold text-center  ">
                Your Account password has been successfully reset, Login with
                your new password
              </h3>
            </div>
          </div>
          <div className="sm:w-[50%] w-[75%] ">
            <div className=" mb-2">
              <Link className="w-full" to="/">
                <Button className="w-full">Login Now</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
