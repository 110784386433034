import React, { useEffect, useState } from "react";
import Button from "../../components/styledComponents/Buttons/Button";
import EmailVerifySuccessImg from "../../assets/images/verification_successful.svg";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { EmailVerifyApi } from "../../apis/auth/AuthApis";
import { setIsVerified } from "../../redux/actions/auth";
import { useDispatch } from "react-redux";
import Logo from "../../assets/images/logo.svg";

const EmailVerifySuccess = () => {
  let { id } = useParams();

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    EmailVerifyFunction();
  }, []);

  async function EmailVerifyFunction() {
    const result = await EmailVerifyApi(id);
    if (result.isSuccess) {
      setIsVerified(result.data.isSuccess, dispatch);
      // navigate("../password-reset-success");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  return (
    <div className="flex flex-col h-screen overflow-hidden bg-lightgrey sm:pt-10 sm:items-center sm:justify-center max-w-screen">
      <div className="pt-0 pb-4 sm:hidden block w-[35%] my-6 mx-8">
        <img src={Logo} className="" alt="logo" />
      </div>
      <div className="sm:max-w-[75%] w-full flex-col items-center h-full justify-center flex">
        <div className="mb-10 sm:mb-20 sm:w-[95%] md:w-[85%] lg:w-[75%] w-[125%] ">
          <img
            src={EmailVerifySuccessImg}
            alt="Email verification"
            className="w-full"
          />
        </div>
        <div className="flex flex-col items-center max-w-full ">
          {/* <div className="flex flex-col items-center justify-center mb-10"> */}
          <div className="max-w-full mb-4 text-center">
            <h2 className="font-sans text-3xl font-semibold text-center sm:text-5xl sm:font-extrabold ">
              Verification Successful
            </h2>
          </div>

          <div className="sm:max-w-[75%] w-full flex flex-col items-center ">
            <div className="mb-8 text-center max-w-[90%]">
              <h3 className="font-sans text-lg font-semibold text-center sm:text-xl ">
                Your Account has been Verified. Please go further with the
                previous account credentials we just sent you.
              </h3>
            </div>
          </div>
          <div className="sm:w-[50%] w-[75%] ">
            <div className="w-full mb-2 ">
              <Link className="" to="/">
                <Button className="w-full">Login Now</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerifySuccess;
