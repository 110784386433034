import React from "react";
import Button from "../../../components/styledComponents/Buttons/Button";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
function Welcome() {
  const user = useSelector((state) => state.user.user);
  const enable = useSelector((state) => state.tfa.is2FA);
  return (
    <div>
      {!(user.kycFlag && enable) && (
        <div className="bg-[#E5F9FF] rounded-2xl px-4 lg:px-6 py-2">
          <div className="py-2">
            <p className="text-xl md:text-2xl font-semibold">
              Welcome,&nbsp;
              <span className="text-[#00C3FF]">
                {user.name}!{/* USER */}
              </span>
            </p>
          </div>
          <div>
            <p className="font-semibold color-[#1D288A] text-lg opacity-50">
              Please verify your identity to deposit funds, Make your first
              investment, and start earning with YIELD-YETI App.
            </p>
          </div>
          <div className="flex gap-4 pt-2">
            {!user.kycFlag && (
              <div className="font-semibold text-lg">
                <Link to="/settings/verification">
                  <Button>Verify Your Identity</Button>
                </Link>
              </div>
            )}
            {!enable && (
              <div className="">
                <Button variant="outline" background="no" text="cyan">
                  <Link to="/settings/security">Enable 2FA</Link>
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Welcome;
