import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function Progress() {
  // const [val,setVal]=useState([0,30])
  return (
    <div className="rounded-2xl bg-white px-4 lg:px-6 py-4">
      <div>
        <p className="text-xl md:text-2xl font-semibold">Reward Tier 1</p>
      </div>
      <div className="w-full py-2">
        <div className="flex justify-between text-[#1D288A] font-semibold text-lg opacity-50">
          <p>1</p>
          <p>2</p>
        </div>
        <div className="">
          <Slider
            max={5000}
            value={1000}
            handleStyle={{
              borderColor: "#00C3FF",
              backgroundColor: "#E5F9FF",
            }}
            railStyle={{ backgroundColor: "#E5F9FF", height: "5" }}
            trackStyle={{ backgroundColor: "#00C3FF", height: "5" }}
          />
        </div>
        <div className="flex justify-between text-xs text-[#1D288A] font-semibold opacity-50">
          <p>YLD 0</p>
          <p>YLD 5000</p>
        </div>
      </div>
    </div>
  );
}

export default Progress;
