import React, { useState, useEffect } from "react";
import LoginImage from "../../assets/images/loginImage.svg";
import Logo from "../../assets/images/logo.svg";
import Input from "../../components/styledComponents/Input/Input.js";
import Button from "../../components/styledComponents/Buttons/Button";
import { Link } from "react-router-dom";
import { LoginApi, SignupGoogleApi } from "../../apis/auth/AuthApis";
import { useDispatch } from "react-redux";
import {
  // setIsVerified,
  setAccessToken,
  setRefreshToken,
  setUserId,
} from "../../redux/actions/auth";
import { useNavigate } from "react-router-dom";
import GoogleLogin from "react-google-login";
import google from "../../assets/images/google.svg";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";
import { set2FA } from "../../redux/actions/2fa";
import { store } from "../../redux/store/store";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState("false");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeRemeber = (e) => {
    setRemember(!remember);
  };

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function handleLogin(e) {
    e.preventDefault();
    if (email.length === 0) {
      setShowApiRes({
        message: "Please enter valid email",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (password.length < 8) {
      setShowApiRes({
        message: "Password must be at least 8 characters long",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (!validateEmail(email)) {
      setShowApiRes({
        message: "Please enter valid email",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else {
      LoginApiFunction();
    }
  }

  const dispatch = useDispatch();
  let navigate = useNavigate();

  async function LoginApiFunction() {
    const result = await LoginApi({
      email: email,
      password: password,
    });
    if (result.isSuccess) {
      setUserId(result.data.response.id, dispatch);
      setRefreshToken(result.data.response.refreshToken, dispatch);
      setAccessToken(result.data.response.accessToken, dispatch);
      navigate("/dashboard");
      // if (result.data.response.id) {
      //   setUserId(result.data.response.id, dispatch);
      //   set2FA(true, dispatch);
      //   navigate("/2fa");
      // } else {
      //   setRefreshToken(result.data.response.refreshToken, dispatch);
      //   setAccessToken(result.data.response.accessToken, dispatch);
      //   set2FA(false, dispatch);
      //   navigate("/dashboard");
      // }
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  async function responseGoogle(response) {
    const result = await SignupGoogleApi({
      token: response.tokenId,
    });
    if (result.isSuccess) {
      if (result.data.response.id) {
        setUserId(result.data.response.id, dispatch);
        // localStorage.setItem("userId", result.data.response.id);
        set2FA(true, dispatch);
        navigate("/2fa");
      } else {
        setRefreshToken(result.data.response.refreshToken, dispatch);
        setAccessToken(result.data.response.accessToken, dispatch);
        // localStorage.setItem("token", result.data.response.accessToken);
        // localStorage.setItem(
        //   "refreshToken",
        //   result.data.response.refreshToken
        // );
        set2FA(false, dispatch);
        navigate("/dashboard");
      }
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    const token = store.getState().auth.access_token;
    if (token) {
      navigate("/dashboard");
    }
  });

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);
  // max-sm:hidden
  // max-sm:col-span-9
  return (
    <div className="grid grid-cols-9 divide-x place-content-center ">
      <div className="hidden md:col-span-4  lg:col-span-5 xl:col-span-6 bg-[#F4F7FA] md:flex md:justify-center md:items-center px-10">
        <img src={LoginImage} alt="Loginimage" className="onboarding-bg" />
      </div>

      <div className="col-span-9 px-8 py-4 border-none md:col-span-5 lg:col-span-4 xl:col-span-3 sm:py-10 md:px-12 sm:px-24">
        <div className="pt-0 pb-4 sm:pt-2 sm:pb-4">
          <img src={Logo} className="max-w-[45%] md:max-w-full" alt="logo" />
        </div>
        <div className="flex flex-col justify-center mt-8 sm:mt-0">
          <div className="mt-8 mb-0 text-4xl font-semibold sm:text-4xl sm:mt-4 sm:mb-4">
            Login
          </div>
          <div className="my-4 font-semibold text-md sm:text-lg">
            Welcome to the yield yeti app. Login to your account with your
            account credentials
          </div>
          <form onSubmit={handleLogin}>
            <div className="py-2">
              <div>
                <Input
                  placeholder="Email address"
                  type="email"
                  className="w-full"
                  value={email}
                  onChange={onChangeEmail}
                />
              </div>
              <div className="pt-4">
                <Input
                  placeholder="Password"
                  type="password"
                  className="w-full"
                  value={password}
                  onChange={onChangePassword}
                />
              </div>
            </div>
            <div className="flex justify-between pt-2 font-semibold text-md sm:text-lg">
              <div>
                <input
                  type="checkbox"
                  id="remember"
                  value="remember"
                  className="mr-2"
                  onClick={onChangeRemeber}
                ></input>
                <label for="remember">Remember Me</label>
              </div>
              <div className="text-[#872BFD]">
                <Link to="/forgot-password">Forgot Password</Link>
              </div>
            </div>
            <div className="">
              {showApiRes.visible && (
                <div className="mt-4">
                  <MessageBox
                    width="100%"
                    variant={showApiRes.variant}
                    message={showApiRes.message}
                    title={showApiRes.title}
                  ></MessageBox>
                </div>
              )}
            </div>
            <div className="font-semibold text-sm sm:text-lg mt-4 text-[#C0CBCE]">
              <p>
                {" "}
                By continuing, you agree to YieldYeti App's&nbsp;
                <a
                  className="text-[#872BFD] underline"
                  href="https://yieldyeti.com/terms-and-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  className="text-[#872BFD] underline"
                  href="https://yieldyeti.com/privacy-policy-2/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="pt-4">
              <Button className="w-full" type="submit">
                Sign Me In
              </Button>
            </div>
          </form>

          {/* <div className="grid content-center grid-cols-11 pt-2">
            <div className="col-span-5 mt-3">
              <hr />
            </div>
            <p className="col-span-1 text-sm font-semibold text-center sm:text-lg">
              OR
            </p>
            <div className="col-span-5 mt-3">
              <hr />
            </div>
          </div> */}
          {/* <div className="pt-2">
            <GoogleLogin
              clientId={process.env.REACT_APP_SIGNUP_GOOGLE}
              render={(renderProps) => {
                renderProps.disabled = false;
                return (
                  <button
                    className="w-full flex items-center justify-center rounded-[15px] px-[14px] py-[12px] bg-[#e5f9ff] border border-[#34cfff] font-semibold"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <img
                      src={google}
                      alt="google"
                      className="h-[30px] w-[30px] mr-2"
                    />
                    Sign In With Google
                  </button>
                );
              }}
              onSuccess={responseGoogle}
              // onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </div> */}
          <div className="flex justify-center py-2 mt-4 font-semibold sm:mt-0">
            <p className="text-sm sm:text-lg">
              Don't have account?{" "}
              <a className="text-[#872BFD]" href="/register">
                Register Here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
