import axios from "axios";
import authHeader from "../../redux/services/auth-header";
import { store } from "../../redux/store/store";
import { handleApiCall } from "../apis";
// import { getData } from "../ip/IpApi";

export const SignupApi = async (data) => {
  const val = await handleApiCall("post", "/register", data, false, "").then(
    (res) => {
      return res;
    }
  );
  return val;
};

export const LoginApi = async (data) => {
  const val = await handleApiCall("post", "/login", data, false, "").then(
    (res) => {
      return res;
    }
  );
  return val;
};

export const ForgotPasswordApi = async (data) => {
  const val = await handleApiCall(
    "post",
    "/forget-password",
    data,
    false,
    ""
  ).then((res) => {
    return res;
  });
  return val;
};

export const ResetPasswordApi = async (data, token) => {
  const val = await handleApiCall(
    "post",
    "/change-forget-password",
    data,
    true,
    {
      Authorization: "Bearer " + token,
    }
  ).then((res) => {
    return res;
  });
  return val;
};

export const ResendMailApi = async (data) => {
  const val = await handleApiCall(
    "post",
    "/resend-email",
    data,
    false,
    ""
  ).then((res) => {
    return res;
  });
  return val;
};

export const SignupGoogleApi = async (data) => {
  const val = await handleApiCall(
    "post",
    "/google-register",
    data,
    false,
    ""
  ).then((res) => {
    return res;
  });
  return val;
};

export const EmailVerifyApi = async (token) => {
  const val = await handleApiCall("get", "/verify-email", {}, true, {
    Authorization: "Bearer " + token,
  }).then((res) => {
    return res;
  });
  return val;
};

export const RefreshAccessTokenApi = async () => {
  const token = store.getState().auth.refresh_token;
  const val = await handleApiCall("get", "/refresh-access", {}, true, {
    Authorization: "Bearer " + token,
  }).then((res) => {
    return res;
  });
  return val;
};

export const AccessTokenApi = async () => {
  const token = store.getState().auth.access_token;
  const val = await handleApiCall("get", "/check-access-token", {}, true, {
    Authorization: "Bearer " + token,
  }).then((res) => {
    return res;
  });
  return val;
};

export const OTPVerificationApi = async (data) => {
  const token = store.getState().auth.user_id;
  const val = await handleApiCall(
    "post",
    `/verify-otp-login/${token}`,
    data,
    false,
    ""
  ).then((res) => {
    return res;
  });
  return val;
};
