import React, { useState, useEffect } from "react";
import HelpImage from "../../assets/images/help_support.svg";
import SettingsLayout from "./SettingsLayout";
import Button from "../../components/styledComponents/Buttons/Button";
import Input from "../../components/styledComponents/Input/Input";
import Textarea from "../../components/styledComponents/Input/Textarea";
import HelpAndSupportApi from "../../apis/help/help";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const SettingsHelp = () => {
  const [subject, setSubject] = useState();
  const [details, setDetails] = useState();

  function handleSubjectChange(e) {
    const subject = e.target.value;
    setSubject(subject);
  }

  function handleDetailChange(e) {
    const details = e.target.value;
    setDetails(details);
  }

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    if (!subject) {
      setShowApiRes({
        message: "Please enter subject",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (details.length < 10) {
      setShowApiRes({
        message: "Please enter some details",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else {
      HelpAndSupportApiFunction();
    }
  }

  async function HelpAndSupportApiFunction() {
    const result = await HelpAndSupportApi({
      description: details,
      subject: subject,
    });
    if (result.isSuccess) {
      setDetails("");
      setSubject("");
      toast.success("Your message has been submitted", {
        position: toast.POSITION.TOP_CENTER,
      });
      setDetails("");
      setSubject("");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <div>
      <SettingsLayout>
        <div className="flex items-center px-0 pt-10 pb-6 lg:px-8">
          <div className="w-full max-h-full px-2 sm:basis-1/2 sm:max-w-full">
            <form onSubmit={handleSubmit}>
              <div className="max-w-full pl-4">
                <div className="max-w-full pb-2 ">
                  <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                    Subject
                  </p>
                </div>
                <div className="max-w-full pb-4 pr-2 ">
                  <Input
                    placeholder="Enter Subject"
                    type="text"
                    className="w-full"
                    bg="transparent"
                    onChange={handleSubjectChange}
                    value={subject}
                  ></Input>
                </div>
                <div className="max-w-full pb-2 ">
                  <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                    Description
                  </p>
                </div>
                <div className="max-w-full pr-2 ">
                  <Textarea
                    placeholder="Enter the details of your request"
                    className="w-full"
                    onChange={handleDetailChange}
                    value={details}
                  ></Textarea>
                </div>
                <div className="">
                  {showApiRes.visible && (
                    <div className="pt-2">
                      <MessageBox
                        width="100%"
                        variant={showApiRes.variant}
                        message={showApiRes.message}
                        title={showApiRes.title}
                      ></MessageBox>
                    </div>
                  )}
                </div>
                <div className="h-full max-w-sm pt-4 pr-20 sm:pr-40">
                  <Button className="w-full" type="submit">
                    Submit Request
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div className="items-center justify-center hidden max-w-full px-2 basis-1/2 sm:flex">
            <div>
              <img
                src={HelpImage}
                alt="Help and Support"
                className="max-w-[60%] md:max-w-[80%]"
              />
            </div>
          </div>
        </div>
      </SettingsLayout>
    </div>
  );
};

export default SettingsHelp;
