import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import CoinTable from "./CoinTable";
import WalletAvailable from "./WalletAvailable";
// import { SET_WALLET } from '../../redux/actions/wallet'
// import { useDispatch } from 'react-redux'
// import { WalletApi } from '../../apis/wallet/WalletApi'

function Wallet() {
  // const dispatch = useDispatch();

  // const getWallet = async() => {
  //     const result = await WalletApi();
  // }

  // useEffect(()=>{
  //     getWallet();
  // })
  return (
    <Layout>
      <div className="flex md:flex-row flex-col gap-2 mx-0 lg:mx-10 max-h-full">
        <div className="w-full md:w-2/3 max-h-full flex flex-col gap-3 bg-white px-4 lg:px-6 py-2 rounded-2xl">
          <p className="font-semibold text-3xl pt-4 color-[#002C3A]">Wallet</p>
          <div className="text-lg opacity-70">
            <a href="/dashboard" className="font-semibold text-[#00C3FF] ">
              Dashboard {" > "}
            </a>
            <span className="font-semibold text-[#000072] ">Wallet</span>
          </div>
          <div className="">
            <CoinTable />
          </div>
        </div>
        <div className="flex flex-col gap-3 w-full md:w-1/3 h-max bg-white rounded-2xl">
          <WalletAvailable />
        </div>
      </div>
    </Layout>
  );
}

export default Wallet;
