import React from "react";
import SettingsLayout from "./SettingsLayout";
// import { Link } from "react-router-dom";
import { useState } from "react";
import SettingsLegalPrivacy from "./SettingsLegalPrivacy";
import SettingsLegalTerms from "./SettingsLegalTerms";
const SettingsLegalHome = () => {
  const [terms, setTerms] = useState(true);

  return (
    <div>
      <SettingsLayout>
        <div className="flex md:flex-row flex-col max-h-full ">
          <div className="basis-1/5 max-w-full max-h-full  border-r-[#C4C4C4] border-b-[#C4C4C4] border-b-[1px] md:border-b-[0px] md:border-r-[1px]">
            <div
              className={` cursor-pointer mb-2 ${
                terms
                  ? "bg-[#E5F9FF]/60 border-[#74D5F3] border-opacity-60 border-[1px]"
                  : "border-[1px] border-[#fff]"
              }`}
            >
              <div className="p-4 sm:p-6 " onClick={() => setTerms(true)}>
                <p
                  className={`text-[#1D288A] font-semibold text-lg sm:text-xl  ${
                    !terms && "opacity-50"
                  }`}
                >
                  Terms of Use
                </p>
              </div>
            </div>
            <div
              className={`cursor-pointer md:mb-2 ${
                !terms
                  ? "bg-[#E5F9FF]/60 border-[#74D5F3] border-opacity-60 border-[1px]"
                  : "border-[1px] border-[#fff]"
              }`}
            >
              <div className="p-4 sm:p-6" onClick={() => setTerms(false)}>
                <p
                  className={`text-[#1D288A] font-semibold text-lg sm:text-xl  ${
                    terms && "opacity-50"
                  }`}
                >
                  Privacy Policy
                </p>
              </div>
            </div>
          </div>

          <div className="basis-4/5 pt-4 pb-10 px-6 sm:px-10 max-w-full h-full border-l-[#C4C4C4]">
            {terms ? <SettingsLegalTerms /> : <SettingsLegalPrivacy />}
          </div>
        </div>
      </SettingsLayout>
    </div>
  );
};

export default SettingsLegalHome;
