import React, { useState, useEffect } from "react";
import Verification from "../../assets/images/identity_verification.svg";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";
import Button from "../../components/styledComponents/Buttons/Button";
import Level2 from "./kyc/Level2";
import Level1 from "./kyc/Level1";
import InlineInquiry from "../../components/persona/persona";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/user";
import { getUserProfileApi } from "../../apis/profile/getUserProfile";


function SettingsVerification() {
  // const user = useSelector((state) => state.user.user);

  const [newUser,setNewUser] = useState()

  const dispatch = useDispatch();

  const getUserProfile = async () => {
    const result = await getUserProfileApi();
    if (result === false) {
      console.log("Error");
    } else {
        setUser(result.data.response, dispatch);
        setNewUser(result.data.response);
    }
  };

  useEffect(() => {
    getUserProfile();
  },[]);
  return (
    <Layout>
      <div className="md:flex-row flex-col flex gap-2 mx-2 lg:mx-10 h-full">
        <div className="bg-white px-6 py-2 rounded-2xl w-full md:w-2/3 max-h-full">
          <p className="font-bold text-2xl md:text-3xl py-4 color-[#002C3A]">
            Identity Verification
          </p>
          <div className="font-semibold text-lg opacity-50">
            <a href="/dashboard" className="font-semibold text-[#00C3FF] ">
              Dashboard {">"}&nbsp;
            </a>
            <a
              href="/settings/profile/"
              className="font-semibold text-[#00C3FF] "
            >
              Setting {">"}
            </a>
            <span className="font-semibold "> Verification</span>
          </div>
          <div className="mt-16 mb-10 md:w-[70%]">
            <img src={Verification} alt="verification" />
          </div>
          {newUser && !newUser.kycFlag ? (
            <div>
              <div className="text-[#959BC9] text-md md:text-lg font-semibold flex flex-col gap-6 pr-10 md:pr-40">
                <p>
                  As a regulated FinTech company, we are required to verify
                  certain information about our users. The personal data you
                  provide will be processed solely by our partner Sumsub.
                </p>
                <p>
                  Prohibited nationalities: American Samoa, Angola, Anguilla,
                  Belarus, British Virgin Islands, Cayman Islands, Central
                  African Republic, Cote d'Ivoire (Ivory Coast), Cuba,
                  Democratic People's Republic of Korea (North Korea), Dominica,
                  DRC (Congo), Eritrea, Ethiopia, Fiji, Guinea-Bissau, Guam,
                  Haiti, Iran, Iraq, Liberia, Libya, Mali, Myanmar, Palau,
                  Panama, Rwanda, Samoa, Seychelles, Sierra Leone, Somalia,
                  South Sudan, Sudan, Syria, Thailand, Trinidad and Tobago,
                  Ukraine (Crimea/Sevastopol regions residents only), United
                  States of America (as well as its residents), US Virgin
                  Islands, Vanuatu, Venezuela, Yemen, Zimbabwe
                </p>
                <p>
                  For more information, please visit our{" "}
                  <Link to="/settings/help-and-support/">
                    <span className="text-[#34CFFF] underline">
                      Help Center.
                    </span>
                  </Link>
                </p>
              </div>
              <div>
                <Button
                  className="w-[40%] my-6"
                  type="submit"
                  onClick={InlineInquiry}
                >
                  Start Level 1 KYC
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex items-center">
              <Button disabled className="mx-8 w-full md:px-0 md:w-[60%] mt-10" variant='outline'>
                Level 1 Kyc Done
              </Button>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 w-full md:w-1/3 max-h-full bg-white rounded-2xl">
          <div>
            <p className="text-[#002C3A] font-bold text-lg lg:text-2xl py-6 px-2 lg:px-6 border-b border-[#C4C4C4]">
              Weekly Withdrawal Limit
            </p>
            <div className="my-8 mx-2 lg:mx-6 ">
              <Level1 />
            </div>
            <div className="mx-2 lg:mx-6">
              <Level2 />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SettingsVerification;
