import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    // <div className="spinner-container">
    //   <div className="loading-spinner"></div>
    // </div>
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  );
}