import React from "react";
import SettingsHeader from "./SettingsHeader";

import Layout from "../../components/layout/Layout";
const SettingsLayout = (props) => {
  return (
    <div>
      <Layout>
        <div className="bg-lightgrey max-w-screen max-h-full mx-0 lg:mx-10 ">
          <div className=" bg-white h-full max-w-full rounded-[20px]">
            <SettingsHeader />
            <div className="">{props.children}</div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default SettingsLayout;
