import React, { useState } from "react";
import NavbarLogo from "../../assets/images/navbar_logo.svg";
import SettingLogo from "../../assets/images/settings_icon.svg";
import SettingActiveLogo from "../../assets/images/settings_active_icon.svg";
import LanguageLogo from "../../assets/images/language_logo.svg";
import CheckButton from "../../components/styledComponents/Buttons/CheckButton";
import Verify from "../../assets/images/verify_setting.svg";
import Wallet from "../../assets/images/wallet.svg";
import WalletSelected from "../../assets/images/wallet_selected.svg";
import Profile from "../../assets/images/profile_setting.svg";
import hamburger from "../../assets/images/hamburger.svg";
import Security from "../../assets/images/security_setting.svg";
import Legal from "../../assets/images/legal_setting.svg";
import Support from "../../assets/images/help_setting.svg";
import Logout from "../../assets/images/logout_setting.svg";
import { NavLink, Link } from "react-router-dom";
import { store } from "../../redux/store/store";
import { SET_ACCESSTOKEN, SET_REFRESHTOKEN } from "../../redux/actions/type";

function Header() {
  const [showOptions, setShowOptions] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    setShowOptions(true);
  };

  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      setShowOptions(false);
    }
  };

  return (
    <>
      <div
        className="hidden sm:flex justify-between py-4 px-4 md:px-16 w-full max-h-full"
        onMouseLeave={() => {
          setShowOptions(false);
        }}
      >
        <div className="flex gap-0  lg:gap-6 items-center font-medium text-lg">
          <img src={NavbarLogo} alt="navbar" />
          <NavLink
            // exact

            to="/dashboard"
            className={({ isActive }) => ` ml-4 lg:ml-0
                            text-lg text-center text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold px-2 py-2 ${isActive && ""}
                        `}
          >
            Dashboard
          </NavLink>
          <NavLink
            // exact
            to="/portfolio"
            className={({ isActive }) => `
                            text-lg text-center text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold px-2 py-2 ${isActive && ""}
                        `}
          >
            Portfolio
          </NavLink>
          <NavLink
            // exact
            to="/fiat"
            className={({ isActive }) => `
                            text-lg text-center text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold px-2 py-2 ${isActive && ""}
                        `}
          >
            Fiat
          </NavLink>
          <NavLink
            // exact
            to="/invest"
            className={({ isActive }) => `
                            text-lg text-center text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold px-2 py-2 ${isActive && ""}
                        `}
          >
            Invest
          </NavLink>
        </div>

        <div className="flex max-h-full">
          <NavLink
            // exact
            to="/wallet"
            className="pr-5 h-full flex justify-center items-center"
          >
            {({ isActive }) =>
              isActive ? (
                <CheckButton logo="wallet" variant="active">
                  Wallet
                </CheckButton>
              ) : (
                <CheckButton logo="wallet" variant="">
                  Wallet
                </CheckButton>
              )
            }
          </NavLink>

          <div className="relative inline-block">
            <div className="h-full w-full">
              <NavLink
                // exact
                to="/settings"
                className=""
              >
                {({ isActive }) =>
                  isActive ? (
                    <button
                      onMouseEnter={handleClick}
                      type="button"
                      className="dropbtn bg-[#00C3FF] rounded-2xl px-2 py-1 flex justify-center items-center max-w-full h-full focus:bg-[#34CFFF] focus:text-white"
                      id="menu-button"
                      aria-expanded="true"
                      aria-haspopup="true"
                    >
                      <img
                        src={SettingActiveLogo}
                        alt="Setting"
                        className="dropbtn h-[60%] w-[60%] mx-1"
                      />
                    </button>
                  ) : (
                    <button
                      onMouseEnter={handleClick}
                      type="button"
                      className="dropbtn bg-[#E5F9FF] rounded-2xl px-2 py-1 flex justify-center items-center max-w-full h-full focus:bg-[#34CFFF] focus:text-white"
                      id="menu-button"
                      aria-expanded="true"
                      aria-haspopup="true"
                    >
                      <img
                        src={SettingLogo}
                        alt="Setting"
                        className="dropbtn h-[60%] w-[60%] mx-1"
                      />
                    </button>
                  )
                }
              </NavLink>
            </div>
            {showOptions && (
              <div
                className="origin-top-right absolute right-0 mt-2 w-64 rounded-2xl bg-white border-[1px] border-[#34CFFF] shadow-[0px_5px_20px_#C0EFFF] z-10"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className=" px-2 py-4 flex flex-col " role="none">
                  <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2 items-center">
                    <Link
                      to="/settings/verification"
                      className=""
                      role="menuitem"
                      tabIndex="0"
                      id="menu-item-0"
                    >
                      {" "}
                      <div className="w-full flex items-center">
                        <div className="">
                          <img src={Verify} alt="Verify" className="" />
                        </div>
                        <div className="pl-2">
                          <p>Verify Your Identity</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2  items-center">
                    <Link
                      to="/settings/profile/"
                      className=""
                      role="menuitem"
                      tabIndex="1"
                      id="menu-item-1"
                    >
                      {" "}
                      <div className="w-full flex items-center">
                        <div className="">
                          <img className="" src={Profile} alt="Profile" />
                        </div>
                        <div className="pl-2">
                          <p>Profile</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2  items-center">
                    <Link
                      to="/settings/security/"
                      className=""
                      role="menuitem"
                      tabIndex="2"
                      id="menu-item-2"
                    >
                      {" "}
                      <div className="w-full flex items-center">
                        <div className="">
                          <img src={Security} alt="Security" className="" />
                        </div>
                        <div className="pl-2">
                          <p>Security</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2  items-center">
                    <Link
                      to="/settings/legal/"
                      className=""
                      role="menuitem"
                      tabIndex="3"
                      id="menu-item-3"
                    >
                      {" "}
                      <div className="w-full flex items-center">
                        <div className="">
                          <img src={Legal} alt="Legal" className="" />
                        </div>
                        <div className="pl-2">
                          <p>Legal</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2  items-center">
                    <Link
                      to="/settings/help-and-support/"
                      className=""
                      role="menuitem"
                      tabIndex="4"
                      id="menu-item-4"
                    >
                      {" "}
                      <div className="w-full flex items-center">
                        <div className="">
                          <img src={Support} alt="Support" className="" />
                        </div>
                        <div className="pl-2">
                          <p>Help & Support</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="flex text-[#FF3169] font-semibold text-lg py-2 content-center pl-2  items-center"
                    onClick={() => {
                      store.dispatch({ type: SET_ACCESSTOKEN, payload: "" });
                      store.dispatch({ type: SET_REFRESHTOKEN, payload: "" });
                      // localStorage.setItem("token", "");
                      // localStorage.setItem("refreshToken", "");
                    }}
                  >
                    <Link
                      to="/"
                      className=""
                      role="menuitem"
                      tabIndex="5"
                      id="menu-item-5"
                    >
                      {" "}
                      <div className="w-full flex items-center">
                        <div className="">
                          <img src={Logout} alt="Logout" className="" />
                        </div>
                        <div className="pl-2">
                          <p>Logout</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="pl-5 h-full flex justify-center items-center">
            <button className="bg-[#E5F9FF] h-full max-w-full rounded-2xl px-2 py-2 flex justify-center items-center">
              <img
                src={LanguageLogo}
                alt="Language"
                className="h-[90%] w-[90%]"
              />
            </button>
          </div>
        </div>
      </div>
      <div className=" flex sm:hidden items-start  w-full h-[82px] bg-[#fff] z-20 top-0 left-0  ">
        <div className="mobile-header w-full justify-between flex bg-[#fff] z-[9999]  top-0 py-4 px-8 border-b-[1px] border-[#A7EAFF]">
          {" "}
          <img src={NavbarLogo} alt="navbar" />
          <div
            className="bg-[#E5F9FF] p-4 rounded-2xl flex md:hidden"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L13 13M1 13L13 1L1 13Z"
                  stroke="#1D288A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <img src={hamburger} alt="hamburger" />
            )}
          </div>
        </div>
        <>
          <div
            className={`${
              menuOpen ? "translate-y-[82px]" : "-translate-y-[100%]"
            }  flex flex-col  items-evenly transition duration-500 ease-in bg-[#fff] w-full z-[999] items-start h-fit absolute shadow-lg`}
          >
            <div className="flex flex-col font-medium text-lg w-full">
              <NavLink
                // exact

                to="/dashboard"
                className={({ isActive }) => `
                            text-lg border-[#A7EAFF] border-b-[1px]  text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold mx-4  py-4 ${isActive && ""}
                        `}
              >
                Dashboard
              </NavLink>
              <NavLink
                // exact
                to="/portfolio"
                className={({ isActive }) => `
                            text-lg border-[#A7EAFF] border-b-[1px]  text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold mx-4 py-4 ${isActive && ""}
                        `}
              >
                Portfolio
              </NavLink>
              <NavLink
                // exact
                to="/fiat"
                className={({ isActive }) => `
                            text-lg border-[#A7EAFF] border-b-[1px]   text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold mx-4 py-4 ${isActive && ""}
                        `}
              >
                Fiat
              </NavLink>
              <NavLink
                // exact
                to="/invest"
                className={({ isActive }) => `
                            text-lg  text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold mx-4 py-4 ${isActive && ""}
                        `}
              >
                Invest
              </NavLink>
              <NavLink
                // exact

                to=""
                // className={({ isActive }) => `
                //             text-lg  text-[#1D288A]  bg-[#E5F9FF] w-full  ${
                //               !isActive && "opacity-50"
                //             } font-semibold py-4 px-4  border-[1px] border-[#A7EAFF] ${
                //   isActive && ""
                // }
                //         `}
                className={` ${
                  window.location.href.indexOf("settings") > -1
                    ? "text-lg  text-[#1D288A]  bg-[#E5F9FF] w-full font-semibold py-4 px-4  border-[1px] border-[#A7EAFF]"
                    : "opacity-50 text-lg  text-[#1D288A]  bg-[#E5F9FF] w-full font-semibold py-4 px-4  border-[1px] border-[#A7EAFF]"
                }`}
              >
                Settings
              </NavLink>
              <div className="">
                <div className=" flex flex-col " role="none">
                  <div className="flex content-center py-4 ml-6 mr-4 border-[#A7EAFF] border-b-[1px] ">
                    <NavLink
                      to="/settings/verification"
                      className={({ isActive }) => `
                            text-lg  text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold ${isActive && ""}
                        `}
                      role="menuitem"
                      tabIndex="0"
                      id="menu-item-0"
                    >
                      {" "}
                      <div className="w-full flex gap-2 items-center">
                        <div className="">
                          <img src={Verify} alt="Verify" className="" />
                        </div>
                        <div className="">
                          <p>Verify Your Identity</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="flex py-4 ml-6 mr-4 border-[#A7EAFF] border-b-[1px]    content-center   items-center">
                    <NavLink
                      to="/settings/profile"
                      className={({ isActive }) => `
                            text-lg  text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold ${isActive && ""}
                        `}
                      role="menuitem"
                      tabIndex="1"
                      id="menu-item-1"
                    >
                      {" "}
                      <div className="w-full flex gap-2 items-center">
                        <div className="">
                          <img className="" src={Profile} alt="Profile" />
                        </div>
                        <div className="">
                          <p>Profile</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="flex  py-4 ml-6 mr-4 border-[#A7EAFF] border-b-[1px]  content-center   items-center">
                    <NavLink
                      to="/settings/security"
                      className={({ isActive }) => `
                            text-lg  text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold ${isActive && ""}
                        `}
                      role="menuitem"
                      tabIndex="2"
                      id="menu-item-2"
                    >
                      {" "}
                      <div className="w-full flex gap-2 items-center">
                        <div className="">
                          <img src={Security} alt="Security" className="" />
                        </div>
                        <div className="">
                          <p>Security</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="flex  py-4 ml-6 mr-4 border-[#A7EAFF] border-b-[1px]  content-center   items-center">
                    <NavLink
                      to="/settings/legal"
                      className={({ isActive }) => `
                            text-lg  text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold ${isActive && ""}
                        `}
                      role="menuitem"
                      tabIndex="3"
                      id="menu-item-3"
                    >
                      {" "}
                      <div className="w-full gap-2 flex items-center">
                        <div className="">
                          <img src={Legal} alt="Legal" className="" />
                        </div>
                        <div className="">
                          <p>Legal</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="flex  py-4 ml-6 mr-4 border-[#A7EAFF] border-b-[1px]  content-center   items-center">
                    <NavLink
                      to="/settings/help-and-support"
                      className={({ isActive }) => `
                            text-lg  text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold ${isActive && ""}
                        `}
                      role="menuitem"
                      tabIndex="4"
                      id="menu-item-4"
                    >
                      {" "}
                      <div className="w-full gap-2 flex items-center">
                        <div className="">
                          <img src={Support} alt="Support" className="" />
                        </div>
                        <div className="">
                          <p>Help & Support</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className="flex text-[#FF3169] font-semibold text-lg py-4 ml-6 mr-4   content-center  items-center"
                    onClick={() => {
                      store.dispatch({ type: SET_ACCESSTOKEN, payload: "" });
                      store.dispatch({ type: SET_REFRESHTOKEN, payload: "" });
                    }}
                  >
                    <Link
                      to="/"
                      className=""
                      role="menuitem"
                      tabIndex="5"
                      id="menu-item-5"
                    >
                      {" "}
                      <div
                        className="w-full flex gap-2  items-center"
                        onClick={() => {
                          localStorage.setItem("token", "");
                          localStorage.setItem("refreshToken", "");
                        }}
                      >
                        <div className="">
                          <img src={Logout} alt="Logout" className="" />
                        </div>
                        <div className="pl-2">
                          <p>Logout</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full mt-8 py-4 border-[#A7EAFF] border-t-[1px] justify-evenly h-fit gap-2">
              <div className="w-[50%] flex items-center justify-center">
                <NavLink to="/wallet" className="" role="menuitem">
                  {({ isActive }) =>
                    isActive ? (
                      <button className="bg-[#00C3FF] h-fit max-w-full rounded-2xl px-8 py-4 flex justify-center items-center">
                        <img src={WalletSelected} alt="Language" className="" />
                        <p className="pl-2 font-semibold text-[#FFFFFF] text-md">
                          Wallet
                        </p>
                      </button>
                    ) : (
                      <button className="bg-[#E5F9FF] h-fit max-w-full rounded-2xl px-8 py-4 flex justify-center items-center">
                        <img src={Wallet} alt="Language" className="" />
                        <p className="pl-2 font-semibold text-[#1D288A] text-md">
                          Wallet
                        </p>
                      </button>
                    )
                  }
                  {/* <button className="bg-[#E5F9FF] h-fit max-w-full rounded-2xl px-8 py-4 flex justify-center items-center">
                    <img src={Wallet} alt="Language" className="" />
                    <p className="pl-2 font-semibold text-[#1D288A] text-md">
                      Wallet
                    </p>
                  </button> */}
                </NavLink>
              </div>
              <div className="w-[50%] flex items-center justify-center">
                <Link to="" className="" role="menuitem">
                  <button className="bg-[#E5F9FF] h-fit max-w-full rounded-2xl px-8 py-4 flex justify-center items-center">
                    <img
                      src={LanguageLogo}
                      alt="Wallet"
                      className="h-[20px] w-[20px]"
                    />
                    <p className="pl-2 font-semibold text-[#1D288A] text-md">
                      Language
                    </p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default Header;