import authHeader from "../../redux/services/auth-header";
import { handleApiCall } from "../apis";

export const HelpAndSupportApi = async (data) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "post",
    "/help-support",
    data,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export default HelpAndSupportApi;
