import React, { useState, useEffect } from "react";
import BTC from "../../assets/images/btc.svg";
import YLD from "../../assets/images/yld.svg";
import ETH from "../../assets/images/eth.svg";
import USDT from "../../assets/images/usdt.svg";
import USDC from "../../assets/images/usdc.svg";
import RightArrow from "../../assets/images/right_arrow.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/css/stylesheet.css";
import { WalletApi } from "../../apis/wallet/WalletApi";
import { useDispatch } from "react-redux";
import { setWallet } from "../../redux/actions/wallet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ContentLoader from "react-content-loader";

const CoinTable = () => {
  // const data = useSelector((state) => state.wallet.WALLET);
  const [data, setData] = useState();

  const dispatch = useDispatch();

  const getWallet = async () => {
    const res = await WalletApi();
    if (res === false) {
      console.log("Error");
    } else {
      // if (result.data.response.result) {
      setWallet(res.data.response.result, dispatch);
      setData(res.data.response.result);
      // }
    }
  };
  // const [dataExist,setDataExist]  = useState(true);
  // if(data){
  //   setDataExist(true);
  // }
  // else{
  //   setDataExist(false);
  // }
  // const data = [
  //   {
  //     src: BTC,
  //     name: "BTC",
  //     value: 0.0,
  //     holdings: 0.0,
  //   },
  //   {
  //     src: YLD,
  //     name: "YLD",
  //     value: 0.0,
  //     holdings: 0.0,
  //   },
  //   {
  //     src: ETH,
  //     name: "ETH",
  //     value: 0.0,
  //     holdings: 0.0,
  //   },
  //   {
  //     src: USDT,
  //     name: "USDT",
  //     value: 0.0,
  //     holdings: 0,
  //   },
  //   {
  //     src: USDC,
  //     name: "USDC",
  //     value: 0.0,
  //     holdings: 0,
  //   },
  // ];

  useEffect(() => {
    getWallet();
  }, []);

  return (
    <div className="overflow-x-auto  px-0">
      {/* <div className="border-[#C4C4C4] border-[1px] rounded-[20px] m-0 md:m-4">
        <div className="flex px-4 md:px-10 py-4 w-[95%]">
          <div className="font-semibold text-xl md:text-2xl font-[#002C3A] w-[33%] text-center">
            Assets
          </div>
          <div className="font-semibold text-xl md:text-2xl font-[#002C3A] w-[33%] text-center ml-2">
            Value
          </div>
          <div className="font-semibold text-xl md:text-2xl font-[#002C3A] w-[33%] text-center ml-2">
            Holding
          </div>
        </div>

        {data ? (
          data.map((val, key) => (
            <div
              key={key}
              value={key}
              className="flex border-t-[1px] border-[#C4C4C4] "
            >
              <div className="flex px-10 py-8 w-[95%] ">
                <div className=" flex items-center w-[33%] justify-center">
                  <p className="font-semibold text-xl md:text-2xl">
                    {val.name}
                  </p>
                </div>
                <div className="   w-[33%]  flex  items-center justify-center ml-2">
                  <p className="font-semibold text-xl md:text-2xl font-[#1D288A] opacity-50">
                    $ 0
                  </p>
                </div>
                <div className=" w-[33%]  flex  items-center justify-center ml-2">
                  <p className="font-semibold text-xl md:text-2xl font-[#002C3A] ">
                    {val.balance}
                  </p>
                </div>
              </div>
              <div className="border-l-[1px] border-[#C4C4C4] flex  items-center justify-center   md:w-[5%] w-[7%]">
                <Link to={`/wallet/asset/${val.tokenAddress}`}>
                  <img src={RightArrow} alt="Right Arrow" />
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center py-10 font-semibold text-xl">
            
            <Skeleton
              count={5}
              duration={7}
            />
            
          </div>
        )}
      </div> */}
      <table className=" table-auto w-full">
        <tr className=" py-8 text-[#002C3A] font-semibold text-left text-lg md:text-2xl">
          <th className="py-6 px-4">Assets</th>
          <th className="py-6 px-4">Value</th>
          <th className="py-6 px-4">Holding</th>
        </tr>

        {data ? (
          data.map((val, key) => (
            <tr className="">
              <td className="py-6 px-4 font-semibold text-lg md:text-xl lg:text-2xl">
                {val.name}
              </td>
              <td className="py-6 px-4 font-semibold text-md md:text-lg lg:text-xl text-[#1D288A] text-opacity-50">
                $0.00
              </td>
              <td className="py-6 px-4  font-semibold text-md md:text-lg lg:text-xl text-[#002C3A]">
                <div className="flex items-center justify-between">
                  {val.balance}
                  <div className>
                    <Link to={`/wallet/asset/${val.tokenAddress}`}>
                      <img src={RightArrow} alt="Right Arrow" />
                    </Link>
                  </div>
                </div>
              </td>
            </tr>

            //  </div>
          ))
        ) : (
          <tr className="">
            <td className="w-[33%] h-full">
              <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                // viewBox="0 0 400 160"
                backgroundColor="#d9d9d9"
                foregroundColor="#ededed"
              >
                <rect x="10" y="30" width="80%" height="20%" />
                <rect x="10" y="85" width="80%" height="20%" />
              </ContentLoader>
            </td>
            <td className="w-[33%] h-full">
              <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                // viewBox="0 0 400 160"
                backgroundColor="#d9d9d9"
                foregroundColor="#ededed"
              >
                {/* <rect y="10" width="70%" height="25%" />
                <rect y="75" width="70%" height="25%" /> */}
                <rect x="10" y="30" width="80%" height="20%" />
                <rect x="10" y="85" width="80%" height="20%" />
              </ContentLoader>
            </td>
            <td className="w-[33%] h-full">
              <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                // viewBox="0 0 400 160"
                backgroundColor="#d9d9d9"
                foregroundColor="#ededed"
              >
                <rect x="10" y="30" width="80%" height="20%" />
                <rect x="10" y="85" width="80%" height="20%" />
                {/* <rect y="10" width="70%" height="25%" />
                <rect y="75" rx="4" ry="4" width="70%" height="25%" /> */}
              </ContentLoader>
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default CoinTable;
