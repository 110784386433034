import {
    SET_PLANS,
} from "../actions/type";

const initialState = {};

export default function foo(state=initialState, action){
    const{type,payload} = action;

    switch(type){
        case SET_PLANS:
        return{
            ...state,
            plans: payload
        }
        default:
            return state;
    }
}