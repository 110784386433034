import React from "react";
import { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
import NoRewards from "../../assets/images/no_rewards.svg";
import { TransactionApi } from "../../apis/transaction/TransactionApi";
import Button from "../../components/styledComponents/Buttons/Button";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "./Rewards.css";

const RewardsTransactions = () => {
  const [showtransactions, setShowTransactions] = useState(true);
  const [transaction, setTransaction] = useState();

  async function GetTransactionsFunction() {
    const result = await TransactionApi();
    if (result.isSuccess) {
      setTransaction(result.data.transactions.reverse());
    }
  }

  function Items({ transaction }) {
    return (
      <div className="overflow-x-auto w-full h-full ">
        <table className=" table-auto w-full">
          <tr className=" py-8 text-[#1D288A] text-opacity-50 font-semibold text-left text-lg md:text-xl">
            <th className="py-6 px-2">Time</th>
            <th className="py-6 px-2">Type</th>
            <th className="py-6 px-2">Asset</th>
            <th className="py-6 px-2">To</th>
            <th className="py-6 px-2">Amount</th>
            <th className="py-6 px-2">Destination</th>
            <th className="py-6 px-2">TxID</th>
            <th className="py-6 px-2">Status</th>
          </tr>
          {console.log(transaction)}
          {transaction &&
            transaction.map((val, key) => (
              // <div key={key} value={key}>
              <tr className="">
                <td className="whitespace-nowrap ext-clip py-6 px-2 font-semibold text-md  lg:text-lg text-[#002C3A]">
                  {moment(val.createdAt).format("DD-MM-YYYY , hh:mm A")}
                </td>
                <td className="py-6 px-2 font-semibold text-md  lg:text-xl text-[#002C3A]">
                  {val.transactionTypeString}
                </td>
                <td className="py-6 px-2  font-semibold text-md  lg:text-xl text-[#002C3A]">
                  {/* <div className="flex items-center justify-between"> */}
                  {val.fromToken?.symbol}
                  {/* </div> */}
                </td>
                <td className="py-6 px-2  font-semibold text-md  lg:text-xl text-[#002C3A]">
                  {/* <div className="flex items-center justify-between"> */}
                  {val.transactionTypeString === "Swap"
                    ? val.toToken?.symbol
                    : "-"}
                  {/* </div> */}
                </td>
                <td className="py-6 px-2 font-semibold text-md  lg:text-xl text-[#002C3A]">
                  {val.transactionTypeString === "Swap"
                    ? val.requestBody?.inputAmount
                    : val.requestBody?.transactionRequest?.value}
                </td>
                <td className="py-6 px-2  font-semibold text-md  lg:text-lg text-[#002C3A]">
                  <div className="flex items-center justify-between">
                    {val.requestBody?.transactionRequest?.to.slice(0, 5)}
                    ...
                    {val.requestBody?.transactionRequest?.to.slice(-5)}
                  </div>
                </td>
                <td className="py-6 px-2 font-semibold text-md  lg:text-lg text-[#002C3A]">
                  {val.transactionHash.slice(0, 5)}...
                  {val.transactionHash.slice(-5)}
                </td>
                <td className="py-6 px-2  font-semibold text-md  lg:text-xl text-[#002C3A]">
                  <div className="flex items-center justify-between">
                    <a
                      href={val.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=""
                    >
                      <Button
                        // variant="outline"
                        background="no"
                        // text="ink"
                        className="w-full"
                      >
                        {" "}
                        View{" "}
                      </Button>
                    </a>
                  </div>
                </td>
              </tr>

              //  </div>
            ))}
        </table>
      </div>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(transaction.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(transaction.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % transaction.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items transaction={currentItems} />
        <ReactPaginate
          className="flex justify-evenly font-semibold pt-2 px-2 mt-4 w-[50%] paginate text-md sm:text-lg"
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  useEffect(() => {
    GetTransactionsFunction();
  }, []);

  return (
    <div className=" bg-white h-full w-full rounded-[20px] py-8 flex flex-col items-center justify-center">
      <div className="border-b-[1px] border-t-[1px] border-b-[#C4C4C4] border-t-[#C4C4C4]  py-6 md:pl-8 flex md:justify-start  justify-center w-full">
        <div
          className={`
            text-md md:text-xl text-center text-[#1D288A] cursor-pointer ${
              !showtransactions && "opacity-50"
            } font-semibold px-2 md:px-4 pb-2 ${
            showtransactions && "border-b-[#000072] border-b-[1px]"
          }
          `}
          onClick={() => setShowTransactions(true)}
        >
          <span>Transaction History</span>
        </div>
        <div
          className={`
            text-md md:text-xl text-center text-[#1D288A] cursor-pointer ${
              showtransactions && "opacity-50"
            } font-semibold ml-2 px-2 md:px-4 pb-2 ${
            !showtransactions && "border-b-[#000072] border-b-[1px]"
          }
          `}
          onClick={() => setShowTransactions(false)}
        >
          <span>Reward History</span>
        </div>
      </div>
      <div className="flex h-full w-full px-6 pt-10 justify-center items-center">
        {showtransactions ? (
          <div className="w-full flex items-center justify-center">
            {transaction && transaction.length ? (
              <div className="w-full flex items-center flex-col">
                <PaginatedItems itemsPerPage={5} />
              </div>
            ) : (
              <img
                src={NoRewards}
                alt="No Rewards/Transactions"
                className="py-40"
              />
            )}
          </div>
        ) : (
          <img
            src={NoRewards}
            alt="No Rewards/Transactions"
            className="py-40"
          />
        )}
      </div>
    </div>
  );
};

export default RewardsTransactions;
