import styled from "styled-components";

/*
      Usage:
      // Primary Button
      <Button> Hello </Button>

      // Outlined Button
        <Button variant='outline'> Hello </Button>

        // Change width
        <Button width='200px'> Hello </Button>
        <Button width='200px' variant='outline'> Hello </Button>
        
 
        //  Button with outline -- default 
        // <Button variant="outline"> Sign me in</Button>


        //  Button with outline  -- ink-colored text
        // <Button variant="outline" text="ink"> Hello </Button>
 

        //  Button with outline  -- cyan-colored text
        // <Button variant="outline" text="cyan"> Hello </Button>
 

        //  Button with outline  -- cyan-colored background
        // <Button variant="outline" background="yes"> Hello </Button>
  

        //  Button with outline  -- transparent background
        // <Button variant="outline" background="no"> Hello </Button>
 
  */

const Button = styled.button`
  // Assumed as it is variable in the design
  border: ${(props) =>
    props.variant === "outline"
      ? props.color === "green"
        ? "1px solid #28d828"
        : "1px solid #34CFFF"
      : "none"};
  border-radius: 15px;
  @media (max-width: 639px) {
    height: 50px;
    font-size: 0.8rem;
    padding: 12px;
  }
  color: ${(props) =>
    props.text
      ? props.text === "ink"
        ? "#1D288A"
        : "cyan"
        ? "#00C3FF"
        : "#000000"
      : props.color === "green"
      ? "#28d828"
      : "#000000"};
  background-color: ${(props) =>
    props.variant === "outline"
      ? props.background === "yes"
        ? "#E5F9FF"
        : "transparent"
      : "#34CFFF"};
  font-size: 1rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  padding: 14px;
`;

export default Button;
