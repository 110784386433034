import React, { useState, useEffect } from "react";
import ResetPasswordImage from "../../assets/images/loginImage.svg";
import Logo from "../../assets/images/logo.svg";
import Input from "../../components/styledComponents/Input/Input.js";
import Button from "../../components/styledComponents/Buttons/Button";
import { ResetPasswordApi } from "../../apis/auth/AuthApis";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  let { id } = useParams();

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  let navigate = useNavigate();

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onConfirmPassword = (e) => {
    const confirmpassword = e.target.value;
    setConfirmpassword(confirmpassword);
  };

  function handleReset(e) {
    e.preventDefault();
    if (password.length < 8) {
      setShowApiRes({
        message: "Password must be atleast 8 characters",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (password !== confirmpassword) {
      setShowApiRes({
        message: "Passwords does not match",
        visible: true,
        variant: "danger",
        title: "Warning",
      });
    } else if (password === confirmpassword) {
      ResetPasswordFunction();
    }
  }

  async function ResetPasswordFunction() {
    const result = await ResetPasswordApi(
      {
        newPassword: password,
      },
      id
    );

    if (result.isSuccess) {
      navigate("../password-reset-success");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <div className="grid grid-cols-9 divide-x place-content-center">
      <div className="hidden md:col-span-4  lg:col-span-5 xl:col-span-6 bg-[#F4F7FA] md:flex md:justify-center md:items-center px-10">
        <img
          src={ResetPasswordImage}
          alt="ResetPassword"
          className="onboarding-bg"
        />
      </div>

      <div className="col-span-9 px-8 py-4 border-none md:col-span-5 lg:col-span-4 xl:col-span-3 sm:py-10 md:px-12 sm:px-24">
        <div className="pt-0 pb-4 sm:pt-2 sm:pb-4">
          <img src={Logo} alt="logo" className="max-w-[45%] md:max-w-full" />
        </div>
        <div className="flex flex-col justify-center mt-8 sm:mt-0">
          <div className="mt-8 mb-0 text-4xl font-semibold sm:text-4xl sm:mt-4 sm:mb-4">
            Reset Password
          </div>
          <div className="my-4 font-semibold text-md sm:text-lg">
            Create New Password for your account
          </div>
          <form onSubmit={handleReset}>
            <div className="py-2">
              <div>
                <Input
                  placeholder="New Password"
                  type="password"
                  className="w-full"
                  value={password}
                  onChange={onChangePassword}
                />
              </div>
              <div className="pt-4">
                <Input
                  placeholder="Re-enter New Password"
                  type="password"
                  className="w-full"
                  value={confirmpassword}
                  onChange={onConfirmPassword}
                />
              </div>
            </div>
            <div className="">
              {showApiRes.visible && (
                <div className="pt-4">
                  <MessageBox
                    width="100%"
                    variant={showApiRes.variant}
                    message={showApiRes.message}
                    title={showApiRes.title}
                  ></MessageBox>
                </div>
              )}
            </div>
            <div className="w-full pt-4">
              <Button className="w-full" type="submit">
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
