import React, { useEffect, useState } from "react";
import NoTransactions from "../../assets/images/no_transactions.svg";
import { TokenTransactionApi } from "../../apis/transaction/TransactionApi";
import Button from "../../components/styledComponents/Buttons/Button";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "../portfolio/Rewards.css";
import "../../assets/css/stylesheet.css";

function Table(props) {
  const [transaction, setTransaction] = useState(null);

  async function GetTransactionsFunction() {
    const result = await TokenTransactionApi(props.id);
    if (result.isSuccess) {
      setTransaction(result.data.transactions.reverse());
    }
  }
  useEffect(() => {
    GetTransactionsFunction();
  }, []);

  function Items({ transaction }) {
    return (
      <div className="overflow-x-auto w-full  ">
        <p className="font-semibold text-2xl color-[#002C3A] pb-4"> History </p>
        {/* <div className="w-max-content overflow-scroll h-full rounded-2xl border border-[#C4C4C4] overflow-scroll">
          <div className="flex px-6 py-4 w-[95%] text-[#1D288A] opacity-50 font-semibold text-lg">
            <div className="w-[20%] ">Time</div>
            <div className="w-[12%] ml-2">Type</div>
            <div className="w-[10%] ml-2">Asset</div>
            <div className="w-[10%] ml-2">Amount</div>
            <div className="w-[18%] ml-2">Destination</div>
            <div className="w-[18%] ml-2">TxID</div>
            <div className="w-[12%] ml-2">Status</div>
          </div>
          {transaction &&
            transaction.map((val, key) => (
              <div
                key={key}
                value={key}
                className="flex border-t-[1px] border-[#C4C4C4]"
              >
                <div className="flex px-6 py-4 w-[95%] font-semibold text-lg text-[#002C3A] items-center">
                  <div className="w-[20%] ">
                    {moment(val.createdAt).format("DD-MM-YYYY , HH:mm")}
                  </div>
                  <div className="w-[12%] ml-2">
                    {val.transactionTypeString}
                  </div>
                  <div className="w-[10%] ml-2">
                    {val.requestBody.transactionRequest.tokenAddress.symbol}
                  </div>
                  <div className="w-[10%] ml-2">
                    {val.requestBody.transactionRequest.value}
                  </div>
                  <div className="w-[18%] ml-2">
                    {val.requestBody.transactionRequest.to.slice(0, 5)}
                    ...
                    {val.requestBody.transactionRequest.to.slice(-5)}
                  </div>
                  <div className="w-[18%] ml-2">
                    {val.transactionHash.slice(0, 5)}...
                    {val.transactionHash.slice(-5)}
                  </div>
                  <div className="w-[12%] ml-2 ">
                    <a
                      href={val.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=""
                    >
                      <Button
                        // variant="outline"
                        background="no"
                        // text="ink"
                        className="w-full"
                      >
                        {" "}
                        View{" "}
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div> */}

        <table className=" table-auto w-full">
          <tr className=" py-8 text-[#1D288A] text-opacity-50 font-semibold text-left text-lg md:text-xl">
            <th className="py-6 px-2">Time</th>
            <th className="py-6 px-2">Type</th>
            <th className="py-6 px-2">Asset</th>
            <th className="py-6 px-2">To</th>
            <th className="py-6 px-2">Amount</th>
            <th className="py-6 px-2">Destination</th>
            <th className="py-6 px-2">TxID</th>
            <th className="py-6 px-2">Status</th>
          </tr>

          {transaction &&
            transaction.map((val, key) => (
              // <div key={key} value={key}>
              <tr className="">
                <td className="whitespace-nowrap ext-clip py-6 px-2 font-semibold text-md  lg:text-lg text-[#002C3A]">
                  {moment(val.createdAt).format("DD-MM-YYYY , hh:mm A")}
                </td>
                <td className="py-6 px-2 font-semibold text-md  lg:text-xl text-[#002C3A]">
                  {val.transactionTypeString}
                </td>
                <td className="py-6 px-2  font-semibold text-md  lg:text-xl text-[#002C3A]">
                  {/* <div className="flex items-center justify-between"> */}
                  {val.fromToken.symbol}
                  {/* </div> */}
                </td>
                <td className="py-6 px-2  font-semibold text-md  lg:text-xl text-[#002C3A]">
                  {/* <div className="flex items-center justify-between"> */}
                  {val.transactionTypeString === "Swap"
                    ? val.toToken?.symbol
                    : "-"}
                  {/* </div> */}
                </td>
                <td className="py-6 px-2 font-semibold text-md  lg:text-xl text-[#002C3A]">
                  {val.transactionTypeString === "Swap"
                    ? val.requestBody?.inputAmount
                    : val.requestBody?.transactionRequest?.value}
                </td>
                <td className="py-6 px-2  font-semibold text-md  lg:text-lg text-[#002C3A]">
                  <div className="flex items-center justify-between">
                    {val.transactionTypeString === "Swap" ? (
                      "-"
                    ) : (
                      <div>
                        {val.requestBody.transactionRequest.to.slice(0, 5)}
                        ...
                        {val.requestBody.transactionRequest.to.slice(-5)}
                      </div>
                    )}
                  </div>
                </td>
                <td className="py-6 px-2 font-semibold text-md  lg:text-lg text-[#002C3A]">
                  {val.transactionHash.slice(0, 5)}...
                  {val.transactionHash.slice(-5)}
                </td>
                <td className="py-6 px-2  font-semibold text-md  lg:text-xl text-[#002C3A]">
                  <div className="flex items-center justify-between">
                    <a
                      href={val.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=""
                    >
                      <Button
                        // variant="outline"
                        background="no"
                        // text="ink"
                        className="w-full"
                      >
                        {" "}
                        View{" "}
                      </Button>
                    </a>
                  </div>
                </td>
              </tr>

              //  </div>
            ))}
        </table>
      </div>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(transaction.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(transaction.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % transaction.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items transaction={currentItems} />
        <ReactPaginate
          className="flex justify-evenly font-semibold pt-2 px-2 mt-4 w-[60%] paginate text-md sm:text-lg"
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  return (
    <div className="w-full h-full ">
      {transaction && transaction.length ? (
        <div className="w-full flex items-center flex-col">
          <PaginatedItems itemsPerPage={5} />
        </div>
      ) : (
        <div className="h-full w-full flex flex-col rounded-2xl border border-[#C4C4C4]">
          <p className="font-semibold text-2xl color-[#002C3A] px-8 py-4 border-b border-[#C4C4C4]">
            History
          </p>
          <div className="flex flex-col justify-center items-center py-20">
            <img src={NoTransactions} alt="No Transaction" />
            <p className="flex justify-center content-center">
              -No Transactions-
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Table;
