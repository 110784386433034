import React from "react";
import styled from "styled-components";
import Copy from "../../../assets/images/copy.svg";
import CheckMark from "../../../assets/images/check_mark.svg";

const Button = styled.button`
   border: ${(props) =>
    props.variant ? "1px solid #34CFFF" : "none" };
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: ${(props) =>
    props.variant ? "#E5F9FF" :  "#34CFFF"};
  display: flex;
  // 16px
  font-size: 1rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  //   Assumed as it is variable in the design
  padding: 16px;
  /* margin-bottom: 0.75rem; */
  // width: ${(props) => (props.width ? props.width : "100px")};
`;

const Icon = styled.img`
  height: 22px;
  width: 22px;
`;

const Label = styled.p`
  font-size: 1rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: black;
`;
const CopyCheckmarkButton = (props) => {
  return (
    <Button variant = {props.variant}>
      {props.logo === "copy" ? <Icon src={Copy} /> : props.logo === "check" ? <Icon src={CheckMark}/>:""}
      
      
      <Label className="pl-2 text-center">{props.children}</Label>
    </Button>
  );
};

export default CopyCheckmarkButton;
