import styled from "styled-components";


  /*
      Usage:
     <Textarea></Textarea>
  */


const Textarea = styled.textarea`
     height: 200px;
   /* width: ${(props) => (props.width ? props.width : "350px")}; */
    background: transparent;
    outline: 1px solid #C4C4C4;
    border-radius: 20px;
    ::placeholder {
    color: #1d288a;
    font-size: 18px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    opacity: 0.5;
  }
  color: #002c3a;
  font-size: 18px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  // margin-bottom: 0.75rem;

  padding: 24px 24px;
  box-sizing: border-box;
  border: none;
  :focus {
    outline: 1px solid #34cfff;
    background: #fbfeff;
    color: black;
    opacity: 1;
  }
`;

export default Textarea;