import React from "react";
import styled from "styled-components";
import Wallet from "../../../assets/images/wallet.svg";
import WalletSelected from "../../../assets/images/wallet_selected.svg";
import Help from "../../../assets/images/help.svg";

const Button = styled.button`
  align-items: center;
  justify-content: center;
  border-radius: 20px;

  padding: 12px 22px;
  background-color: ${(props) => (props.variant ? "#00C3FF" : "#E5F9FF")};
  // background-color:  #E5F9FF;

  display: flex;
  // column-gap: 2px;
  border: none;
  @media (max-width: 768px) {
    height: 50px;
    font-size: 0.8rem;
    padding: 12px;
  }
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const Label = styled.p`
  font-size: 1.125rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: ${(props) => (props.variant ? "#FFFFFF" : "#1d288a")};
  // color: #1D288A;
`;
const CheckButton = (props) => {
  return (
    <Button variant={props.variant}>
      {
        // props.logo==='wallet'
        //   ? props.variant
        //     ? <Icon src={WalletSelected} />
        //     : <Icon scr={Wallet}/>
        //   : <Icon src={Help}/>
        props.variant ? (
          props.logo === "wallet" ? (
            <Icon src={WalletSelected} />
          ) : (
            <div></div>
          )
        ) : props.logo === "wallet" ? (
          <Icon src={Wallet} />
        ) : (
          <Icon src={Help} />
        )
      }
      <Label variant={props.variant} className="pl-2  text-center">
        {props.children}
      </Label>
    </Button>
  );
};

export default CheckButton;
