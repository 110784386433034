import {
    SET_2FA,
} from "../actions/type";

const initialState = {};

export default function foo(state=initialState, action){
    const{type,payload} = action;

    switch(type){
        case SET_2FA:
        return{
            ...state,
            is2FA: payload
        }
        default:
            return state;
    }
}