import React from "react";
import Layout from "../../components/layout/Layout";

function Fiat() {
  return (
    <Layout>
      <iframe
          width="100%"
          height="100%"
          src="https://staging-global.transak.com/?apiKey=WpfLCoyZriOKou6rgGzPbsJusIDs7AhvHQhVrlBO82pXtyWDzT8qAJ45oXj8H8KG"
          allowFullScreen
          title="Video"
          frameborder="0"
        ></iframe>
      {/* <div className="flex md:flex-row flex-col gap-2 mx-0 lg:mx-10 max-h-full">
        Fiat
      </div> */}
    </Layout>
  );
}

export default Fiat;
