import React, { useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import Button from "../../../components/styledComponents/Buttons/Button";
import { Link } from "react-router-dom";

function Chart() {
  const [width, setWidth] = useState(window.screen.width);
  function reportWindowSize() {
    setWidth(window.screen.width);
  }

  window.onresize = reportWindowSize;
  const data = [
    { name: "12:00 AM", uv: 0.4 },
    // { name: "12:30 AM", uv: 0.9 },
    { name: "01:00 AM", uv: 0.3 },
    // { name: "01:30 AM", uv: 0.4 },
    { name: "02:00 AM", uv: 0.5 },
    // { name: "02:30 AM", uv: 0.7 },
    { name: "03:00 AM", uv: 0.7 },
    // { name: "03:30 AM", uv: 0.1 },
    { name: "04:00 AM", uv: 0.2 },
    // { name: "04:30 AM", uv: 0.3 },
    { name: "05:00 AM", uv: 0.9 },
    // { name: "05:30 AM", uv: 0.5 },
    { name: "06:00 AM", uv: 0.6 },
    // { name: "06:30 AM", uv: 0.8 },
    { name: "07:00 AM", uv: 0.1 },
    // { name: "07:30 AM", uv: 0.3 },
    { name: "08:00 AM", uv: 0.3 },
    // { name: "08:30 AM", uv: 0.5 },
    { name: "09:00 AM", uv: 0.8 },
    // { name: "09:30 AM", uv: 0.1 },
    { name: "10:00 AM", uv: 0.5 },
    // { name: "10:30 AM", uv: 0.9 },
    { name: "11:00 AM", uv: 0.2 },
    // { name: "11:30 AM", uv: 0.5 },
    { name: "12:00 PM", uv: 0.9 },
    // { name: "12:30 PM", uv: 0.4 },
    { name: "01:00 PM", uv: 0.4 },
    // { name: "01:30 PM", uv: 0.3 },
    { name: "02:00 PM", uv: 0.7 },
    // { name: "02:30 PM", uv: 0.5 },
    { name: "03:00 PM", uv: 0.1 },
    // { name: "03:30 PM", uv: 0.7 },
    { name: "04:00 PM", uv: 0.3 },
    // { name: "04:30 PM", uv: 0.2 },
    { name: "05:00 PM", uv: 0.5 },
    // { name: "05:30 PM", uv: 0.9 },
    { name: "06:00 PM", uv: 0.8 },
    // { name: "06:30 PM", uv: 0.6 },
    { name: "07:00 PM", uv: 0.3 },
    // { name: "07:30 PM", uv: 0.1 },
    { name: "08:00 PM", uv: 0.5 },
    // { name: "08:30 PM", uv: 0.3 },
    { name: "09:00 PM", uv: 0.1 },
    // { name: "09:30 PM", uv: 0.8 },
    { name: "10:00 PM", uv: 0.9 },
    // { name: "10:30 PM", uv: 0.5 },
    { name: "11:00 PM", uv: 1.5 },
    // { name: "11:30 PM", uv: 0.2 },
    { name: "12:00 AM", uv: 3 },
  ];

  const [time, setTime] = useState("24");
  return (
    <div className="w-full border border-[#C4C4C4] rounded-2xl my-4">
      <div className="py-4 md:py-6 px-4 border-b border-[#C4C4C4]">
        <p className="text-[#002C3A] text-xl md:text-2xl font-bold">
          {" "}
          Earnings{" "}
        </p>
      </div>
      <div className="pt-6 px-2 md:px-4 flex gap-2 md:gap-4">
        <div onClick={() => setTime("24")}>
          {time === "24" ? (
            <Link to="">
              <Button>Last 24 Hours : $ 0.00</Button>
            </Link>
          ) : (
            <Link to="">
              <Button variant="outline" background="no">
                Last 24 Hours : $ 0.00
              </Button>
            </Link>
          )}
        </div>
        <div onClick={() => setTime("7")}>
          {time === "7" ? (
            <Link to="">
              <Button>7 Days</Button>
            </Link>
          ) : (
            <Button variant="outline" background="no">
              <Link to="">7 Days</Link>
            </Button>
          )}
        </div>
        <div onClick={() => setTime("30")}>
          {time === "30" ? (
            <Link to="">
              <Button>30 Days</Button>
            </Link>
          ) : (
            <Link to="">
              <Button variant="outline" background="no">
                30 Days
              </Button>
            </Link>
          )}
        </div>
      </div>
      <div className="py-2 md:py-6">
        {width > 768 ? (
          <ResponsiveContainer width="90%" height={400}>
            <LineChart data={data}>
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#872BFD"
                dot={false}
                strokeWidth={2}
              />
              <CartesianGrid
                stroke="#C0EFFF"
                vertical
                horizontal={true}
                verticalFill={["#ffffff", "#C0EFFF"]}
                fillOpacity={0.4}
              />
              <XAxis
                dataKey="name"
                fontSize={12}
                className="overflow-x-scroll"
                axisLine={false}
              />
              <YAxis axisLine={false} />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="95%" height={200}>
            <LineChart data={data}>
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#872BFD"
                dot={false}
                strokeWidth={2}
              />
              <CartesianGrid
                stroke="#C0EFFF"
                vertical
                horizontal={true}
                verticalFill={["#ffffff", "#C0EFFF"]}
                fillOpacity={0.4}
              />
              <XAxis
                dataKey="name"
                fontSize={11}
                className="overflow-x-scroll"
                axisLine={false}
              />
              <YAxis axisLine={false} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}

export default Chart;
