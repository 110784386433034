import React, { useState } from "react";
import Profile from "../../assets/images/no_profile_picture.svg";
import SettingsLayout from "./SettingsLayout";
import Input from "../../components/styledComponents/Input/Input";
import { useSelector } from "react-redux";
import { ProfilePhotoApi } from "../../apis/profile/getUserProfile";
import { getUserProfileApi } from "../../apis/profile/getUserProfile";
import { setUser } from "../../redux/actions/user";
import { useDispatch } from "react-redux";

const SettingsProfile = () => {
  const user = useSelector((state) => state.user.user);

  const [name, setName] = useState(user.name);

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const dispatch = useDispatch();

  async function handleSubmit(e) {
    e.preventDefault();
    const img = e.target.files[0];
    let fd = new FormData();
    fd.append("file", img);
    const result = await ProfilePhotoApi(fd);
    if (result.isSuccess) {
      const result = await getUserProfileApi();
      if (result === false) {
        console.log("Error");
      } else {
        if (result.data.response) {
          setUser(result.data.response, dispatch);
        }
      }
    }
  }

  // async function ProfilePhotoFunction(){
  //   const result = await ProfilePhotoApi(
  //     {
  //       file: img,
  //     }
  //   );
  // }

  return (
    <div>
      <SettingsLayout>
        <div className=" px-4 sm:px-8 flex sm:flex-row flex-col">
          <div className="basis-1/4 px-2 max-w-full max-h-full">
            <div className=" bg-[#E5F9FF]/60 border-[1px]  border-[#74D5F3]/60 max-w-full px-20 lg:px-24 max-h-full rounded-[20px]  flex flex-col justify-center items-center py-16 my-10">
              {user.photoUrl ? (
                <div className="mb-8  opacity-100 rounded-full w-[170px] h-[170px] overflow-hidden align-middle">
                  <img
                    src={user.photoUrl}
                    alt="No Profile"
                    className="w-full h-full align-middle"
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <div className="mb-8 max-w-full opacity-100">
                    <img
                      src={Profile}
                      alt="No Profile"
                      className="max-w-full"
                    />
                  </div>
                  <div className="mb-6 max-w-full">
                    <p className="font-semibold text-xl text-[#1D288A] opacity-50 text-center">
                      No profile picture found
                    </p>
                  </div>
                </div>
              )}
              <div className="mb-8 w-full mt-4">
                {/* <form onSubmit={}> */}
                <input
                  type="file"
                  id="files"
                  placeholder="Upload"
                  className="hidden"
                  onChange={handleSubmit}
                />
                <label
                  htmlFor="files"
                  className="flex items-center justify-center border border-[#00C3FF] bg-[#E5F9FF] rounded-2xl px-8 py-4 font-semibold text-[#00C3FF] font-[18px]"
                >
                  Upload
                </label>
                {/* </input> */}
                {/* </form> */}
                {/* <Button
                  // for="files"
                  variant="outline"
                  background="yes"
                  text="cyan"
                  className="w-full"
                >
                  Upload
                </Button> */}
              </div>
            </div>
          </div>
          <div className="w-full sm:mt-10 mb-4 sm:mb-0">
            <div className="w-full sm:pl-8">
              <div className="w-full">
                <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                  Name
                </p>
              </div>
              <div className="md:max-w-[80%] lg:max-w-[70%] xl:max-w-[40%] sm:mb-0 mb-4">
                <Input
                  placeholder="Enter Your Username"
                  type="text"
                  value={name}
                  className="w-full"
                  bg="transparent"
                  color="dark"
                  onChange={onChangeName}
                ></Input>
              </div>
              <div className="max-w-full">
                <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                  Email Address
                </p>
              </div>
              <div className="md:max-w-[80%] lg:max-w-[70%] xl:max-w-[40%] sm:mb-0 mb-4">
                <Input
                  placeholder="Enter Your Email Address"
                  type="email"
                  value={user.email}
                  className="w-full"
                  bg="transparent"
                  color="dark"
                  disabled
                ></Input>
              </div>
            </div>
          </div>
        </div>
      </SettingsLayout>
    </div>
  );
};

export default SettingsProfile;
