import { SET_WALLET, SET_COINS, SET_HOLDINGS } from "./type";

export const setWallet = (value, dispatch) =>
  dispatch({ type: SET_WALLET, payload: value });

export const setCoins = (value, dispatch) =>
  dispatch({ type: SET_COINS, payload: value });

export const setHoldings = (value, dispatch) =>
  dispatch({ type: SET_HOLDINGS, payload: value});
