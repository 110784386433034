import React, { useState, useEffect } from "react";
import ForgotPasswordImage from "../../assets/images/forgot_password.svg";
import Logo from "../../assets/images/logo.svg";
import Input from "../../components/styledComponents/Input/Input.js";
import Button from "../../components/styledComponents/Buttons/Button";
import { ForgotPasswordApi } from "../../apis/auth/AuthApis";
import { useNavigate } from "react-router-dom";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";

function ForgotPassord(props) {
  const [email, setEmail] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function handleForgot(e) {
    e.preventDefault();
    if (email.length === 0) {
      setShowApiRes({
        message: "Please enter valid email",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (!validateEmail(email)) {
      setShowApiRes({
        message: "Please enter valid email",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else {
      ForgotPasswordFunction();
    }
  }

  let navigate = useNavigate();

  async function ForgotPasswordFunction() {
    const result = await ForgotPasswordApi({
      email: email,
    });
    if (result.isSuccess) {
      localStorage.setItem("email", email);
      navigate("../email-verify");
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <div className="grid grid-cols-9 divide-x place-content-center">
      <div className="hidden md:col-span-4  lg:col-span-5 xl:col-span-6 bg-[#F4F7FA] md:flex md:justify-center md:items-center px-10">
        <img
          src={ForgotPasswordImage}
          alt="ForgotPassword"
          className="onboarding-bg"
        />
      </div>

      <div className="col-span-9 px-8 py-4 border-none md:col-span-5 lg:col-span-4 xl:col-span-3 sm:py-10 md:px-12 sm:px-24">
        <div className="pt-0 pb-4 sm:pt-2 sm:pb-4">
          <img src={Logo} alt="logo" className="max-w-[45%] md:max-w-full" />
        </div>

        <div className="flex flex-col justify-center mt-8 sm:mt-0">
          <div className="mt-8 mb-0 text-4xl font-semibold sm:text-4xl sm:mt-4 sm:mb-4">
            Forgot Password
          </div>
          <div className="my-4 font-semibold text-md sm:text-lg">
            It's sad that you arrived here. But Don't worry we will fix your
            problem.
          </div>

          <form onSubmit={handleForgot} className="w-full">
            <div className="pt-8">
              <Input
                placeholder="Email address"
                type="text"
                className="w-full"
                value={email}
                onChange={onChangeEmail}
              />
            </div>
            <div className="">
              {showApiRes.visible && (
                <div className="pt-4 pl-2">
                  <MessageBox
                    width="100%"
                    variant={showApiRes.variant}
                    message={showApiRes.message}
                    title={showApiRes.title}
                  ></MessageBox>
                </div>
              )}
            </div>
            <div className="w-full pt-4">
              <Button className="w-full" type="submit">
                Verify Email Address
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassord;
