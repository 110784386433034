import React from "react";
import { NavLink } from "react-router-dom";
const SettingsHeader = () => {
  return (
    <div>
      <div className="border-b-[1px] border-b-[#C4C4C4] pl-4 sm:pl-8 pt-4 pb-1 max-w-full">
        <div className=" mb-4 w-full h-full">
          <h1 className=" text-2xl sm:text-3xl font-semibold pb-3">Settings</h1>
          <div className="font-semibold text-md sm:text-lg opacity-70">
            <a href="/" className="font-semibold text-[#00C3FF] ">
              Dashboard {">"}
            </a>
            <span className="font-semibold "> Settings</span>
          </div>
        </div>
      </div>
      <div className="border-b-[1px] border-b-[#C4C4C4] py-4 sm:py-6 flex  items-center justify-center justify-evenly sm:justify-start">
        <NavLink
          to="/settings/profile"
          className={({ isActive }) => `
            text-md sm:text-lg text-center text-[#1D288A] sm:px-4 sm:ml-4 ${
              !isActive && "opacity-50"
            } font-semibold pb-2 ${
            isActive
              ? "border-b-[#000072] border-b-[1px]"
              : " border-b-[#fff] border-b-[1px]"
          }
          `}
          //Can also do this way:
          // className="text-xl text-center text-[#1D288A] font-semibold ml-4 px-4 pb-2 border-b-[#000072] border-b-[1px] opacity-100"
          // style={({ isActive }) => {
          //   return {
          //     opacity: !isActive && "0.5",
          //     border : !isActive && "none",

          //   };
          // }}
        >
          <span>Profile</span>
        </NavLink>
        <NavLink
          to="/settings/security"
          className={({ isActive }) => `
            text-md sm:text-lg text-center text-[#1D288A] sm:px-4 sm:ml-4 ${
              !isActive && "opacity-50"
            } font-semibold pb-2 ${
            isActive
              ? "border-b-[#000072] border-b-[1px]"
              : " border-b-[#fff] border-b-[1px]"
          }
          `}
        >
          <span>Security</span>
        </NavLink>
        <NavLink
          to="/settings/legal"
          className={({ isActive }) => `
           text-md sm:text-lg text-center text-[#1D288A] sm:px-4 sm:ml-4 ${
             !isActive && "opacity-50"
           } font-semibold pb-2 ${
            isActive
              ? "border-b-[#000072] border-b-[1px]"
              : " border-b-[#fff] border-b-[1px]"
          }
          `}
        >
          <span>Legal</span>
        </NavLink>

        <NavLink
          to="/settings/help-and-support"
          className={({ isActive }) => `
            text-md sm:text-lg text-center text-[#1D288A] sm:px-4 sm:ml-4 ${
              !isActive && "opacity-50"
            } font-semibold pb-2 ${
            isActive
              ? "border-b-[#000072] border-b-[1px]"
              : " border-b-[#fff] border-b-[1px]"
          }
          `}
        >
          <span>Help & Support</span>
        </NavLink>
      </div>
    </div>
  );
};

export default SettingsHeader;
