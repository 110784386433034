import { SET_USER } from "../actions/type";

const initialState = {};

export default function foo(state = initialState, action) {
    const { type, payload } = action;
    switch(type) {
        case SET_USER:
            return {
                ...state, 
                user: payload
              }
        default:
            return state;
    }
}