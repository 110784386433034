import authHeader from "../../redux/services/auth-header";
import { handleApiCall } from "../apis";

export const getUserProfileApi = async (data) => {
  let header = await authHeader();
  const val = await handleApiCall("get", "/get-profile", {}, true, header).then(
    (res) => {
      return res;
    }
  );
  return val;
};

export const ChangePasswordApi = async (data, token) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "put",
    "/change-password",
    data,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const ProfilePhotoApi = async (data) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "post",
    "/profile-photo",
    data,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const CompleteKYCApi = async (data) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "put",
    "/complete-kyc",
    data,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

// let config = {
//   method: 'put',
//   url: `${process.env.REACT_APP_API_URL}/complete-kyc`,
//   headers: {
//     'Content-Type': 'application/json',
//     Authorization: "Bearer " + localStorage.getItem("token")
//   },
//   data : data
// };

// let result = await axios(config);
