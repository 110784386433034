import { SET_WALLET, SET_COINS, SET_HOLDINGS } from "../actions/type";

const initialState = {};

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_WALLET:
      return {
        ...state,
        WALLET: payload,
      };
    case SET_COINS:
      return {
        ...state,
        COINS: payload,
      };
    case SET_HOLDINGS:
      return {
        ...state,
        HOLDINGS: payload,
      }
    default:
      return state;
  }
}
