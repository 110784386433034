import React, { useEffect } from "react";
import SettingsLayout from "./SettingsLayout";
import { useNavigate } from "react-router-dom";

const SettingsHome = () => {
  let navigate = useNavigate();
  useEffect(()=>{
    navigate("/settings/profile")
  })
  return (
    <div>
{/* <Layout> */}
      <SettingsLayout>
        {" "}
        <div className="text-4xl"></div>
      </SettingsLayout>
      {/* </Layout> */}
    </div>
  );
};

export default SettingsHome;
