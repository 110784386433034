import React, { useState } from "react";
import Button from "../../components/styledComponents/Buttons/Button";
import VerifyEmail from "../../assets/images/verify_email.svg";
import Logo from "../../assets/images/logo.svg";
import { ResendMailApi } from "../../apis/auth/AuthApis";
import { setIsVerified } from "../../redux/actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const EmailVerify = () => {
  const email = localStorage.getItem("email");

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const dispatch = useDispatch();
  let navigate = useNavigate();

  function handleClick(e) {
    e.preventDefault();
    ResendFunction();
  }

  async function ResendFunction() {
    const result = await ResendMailApi({
      email: email,
    });
    if (result.isSuccess) {
      setIsVerified(result.data.isVerified, dispatch);
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  return (
    <div className="flex flex-col h-screen bg-lightgrey sm:pt-10 sm:items-center sm:justify-center max-w-screen ">
      <div className="pt-0 pb-4 sm:hidden block w-[35%] my-6 mx-8">
        <img src={Logo} className="" alt="logo" />
      </div>
      <div className="sm:max-w-[75%] w-full flex-col items-center h-full justify-center flex">
        <div className="mb-10 sm:max-w-[75%] max-w-[65%]">
          <img src={VerifyEmail} alt="Email verification" className="w-full" />
        </div>
        <div className="flex flex-col items-center max-w-full ">
          {/* <div className="flex flex-col items-center justify-center mb-10"> */}
          <div className="max-w-full mb-4 text-center">
            <h2 className="font-sans text-3xl font-semibold text-center sm:text-5xl sm:font-extrabold ">
              Verify Your Email Address
            </h2>
          </div>
          <div className="sm:max-w-[75%] w-full flex flex-col items-center ">
            <div className="mb-8 text-center max-w-[90%]">
              <h3 className="font-sans text-lg font-semibold text-center sm:text-xl ">
                Please click the confirmation link in the email we just sent
                you.
              </h3>
            </div>
          </div>
          <div className="sm:w-[75%] w-[80%] ">
            <div className="w-full mb-2">
              <Button className="w-full" disabled>
                Check Email Now
              </Button>
            </div>
          </div>
          <div className="mb-4 text-center">
            <a
              className="font-sans font-semibold text-center text-link"
              href=""
              onClick={handleClick}
            >
              Resend Email
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerify;
