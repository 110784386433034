import React, { useEffect, useState } from "react";
import Input from "../../../components/styledComponents/Input/Input";
import { InvestToken } from "../../../apis/invest/InvestApi";
import { InitializeWithdrawApi } from "../../../apis/transaction/TransactionApi";
import "./invest.css";
import MessageBox from "../../../components/styledComponents/MessageBox/MessageBox";
import Otp from "../otp/Otp";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
Modal.setAppElement("#root");

function InvestPlan(props) {
  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  /* On hovering the whole section of Invest component */
  const [ishover, showIsHover] = useState(false);

  function handleHover() {
    showIsHover(true);
  }

  function handleLeave() {
    showIsHover(false);
  }
  /*Complete Hover of whole Invest Component*/

  /* Enter the Amount to be invested*/
  const [amount, setAmount] = useState();

  const onAmountChange = (e) => {
    const amount = e.target.value;
    setAmount(amount);
  };

  const [enterValue, setEnterValue] = useState(false);

  function handleButtonClick(e) {
    // e.preventDefault();
    setEnterValue(!enterValue);
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    if (!amount) {
      setShowApiRes({
        message: "Please enter valid Amount",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else {
      InitializeApiFunction();
    }
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  async function InitializeApiFunction() {
    const result = await InitializeWithdrawApi();
    if (result.isSuccess) {
      setModalIsOpen(true);
    }
  }

  const [transToken, setTransToken] = useState();
  const [callApi, setCallApi] = useState(false);

  useEffect(() => {
    if (callApi) {
      CallFinalApiFunction();
      // setCallApi(false);
    }
  }, [callApi]);

  async function CallFinalApiFunction() {
    const result = await InvestToken(
      {
        planId: props.Id,
        amount: amount,
      },
      transToken
    );

    if (result.isSuccess) {
      toast.success("Transaction Done", {
        position: toast.POSITION.TOP_CENTER,
      });
      setAmount("");
      setEnterValue(false);
      showIsHover(false);
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
    if (result.data.isSuccess) {
      setCallApi(false);
    } else {
      console.log("Error");
    }
  }
  /* Compeleted the Amount to be invested*/
  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);
  return (
    <div
      className="border rounded-2xl"
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <div className="px-4 py-2 border-b md:py-4 md:px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center ">
            <p
              className={`font-bold text-lg md:text-xl sm:text-[#1D288A] text-[#002C3A] sm:opacity-50 ${
                ishover && "sm:opacity-100 sm:text-[#002C3A]"
              } `}
            >
              Plan Name
            </p>
            {/* <img
              src={Info}
              alt="info"
              className="z-10 pl-2"
              onMouseEnter={handleClick}
              onMouseLeave={handleLeaveInfo}
            /> */}
            {/* {showInfo && (
              <span class="origin-right absolute left-50 max-h-[200px] z-10 bg-[#ffffff] border rounded-2xl px-2 py-1 w-[25%]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                quis lectus ornare, eleifend lorem non, sagittis purus. Integer
                nec dignissim felis. Sed dolor arcu, egestas quis luctus ac,
                pulvinar at nibh. Nullam vehicula sit amet diam nec rhoncus.
                Duis et libero dignissim, aliquam nisl vitae, venenatis lectus.
                Praesent volutpat nisi vel nisi feugiat tincidunt. 
              </span>
             )}  */}
            {/* <div className="ml-2 font-medium tooltip">
              <img src={Info} alt="" className="" />
              <span class="tooltiptext w-96">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero,
                est sunt! Ullam dolor exercitationem molestiae voluptatem,
                excepturi iure in quos voluptate, libero commodi reiciendis ut,
                tempore iste beatae ipsam nulla.
              </span>
            </div> */}
            <div className="ml-2 cursor-pointer tooltip">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="#1D288A66"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="tooltiptext ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                quis lectus ornare,
              </span>
            </div>
          </div>
          <div>
            {" "}
            {enterValue ? (
              <button
                onClick={handleButtonClick}
                className="text-[#000000] text-lg font-bold flex items-center justify-center  md:hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button
                onClick={handleButtonClick}
                className="block md:hidden text-[#000000] text-md bg-[#34Cfff] rounded-2xl px-4 py-2 font-semibold"
              >
                {" "}
                Invest Now
              </button>
            )}
            {ishover && (
              <div className="max-h-[20px] flex items-center justify-center">
                {enterValue ? (
                  <button
                    onClick={handleButtonClick}
                    className="text-[#000000] text-lg font-bold hidden md:flex items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={handleButtonClick}
                    className="md:block hidden text-[#000000] text-md bg-[#34Cfff] rounded-2xl px-4 py-2 font-semibold"
                  >
                    {" "}
                    Invest Now
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        {enterValue && (
          <div className="h-max">
            <form
              className="flex items-center justify-between w-full pt-4"
              onSubmit={handleFormSubmit}
            >
              <div className="flex flex-col items-start gap-2 sm:flex-row sm:items-center">
                <p className="text-lg font-semibold">Enter Amount:</p>
                <Input
                  placeholder="Enter Amount"
                  type="number"
                  height="40px"
                  className="sm:ml-4 sm:w-[50%]"
                  step="any"
                  // value={emailOtp}
                  onChange={onAmountChange}
                />
                <div className="flex items-center justify-center ">
                  <button
                    type="submit"
                    className="text-[#000000] text-md bg-[#34Cfff] rounded-2xl px-4 py-2 font-semibold"
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </form>
            {/* <div> */}
            {showApiRes.visible && (
              <div className="pt-2 pl-2">
                <MessageBox
                  width="100%"
                  variant={showApiRes.variant}
                  message={showApiRes.message}
                  title={showApiRes.title}
                ></MessageBox>
              </div>
            )}
            {/* <MessageBox
                width="100%"
                variant={showApiRes.variant}
                message={showApiRes.message}
                title={showApiRes.title}
              ></MessageBox> */}
            <Modal
              isOpen={modalIsOpen}
              className="flex items-center justify-center w-full h-full"
            >
              <Otp
                setModalIsOpen={setModalIsOpen}
                setCallApi={setCallApi}
                setTransToken={setTransToken}
              />
            </Modal>
            {/* </div> */}
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 py-4 lg:grid-cols-4 ">
        <div className="mx-2">
          <p className="text-#1D288A font-semibold opacity-50 text-lg">Risk</p>
          {props.risk === "LOW" ? (
            <p className="font-semibold text-lg md:text-xl text-[#2BA24C]">
              {props.risk}
            </p>
          ) : props.risk === "MEDIUM" ? (
            <p className="font-semibold text-lg md:text-xl text-[#F08201]">
              {props.risk}
            </p>
          ) : (
            <p className="font-semibold text-lg md:text-xl text-[#FF3169]">
              {props.risk}
            </p>
          )}
        </div>
        <div className="mx-2">
          <p className="text-#1D288A font-semibold opacity-50 text-lg">
            Fix APY
          </p>
          <p className="text-lg font-semibold md:text-xl">{props.Fix_APY}</p>
        </div>
        <div className="mx-2">
          <p className="text-#1D288A font-semibold opacity-50 text-lg">
            Reward APY
          </p>
          <p className="text-lg font-semibold md:text-xl">{props.Reward_APY}</p>
        </div>
        <div className="mx-2">
          <p className="text-#1D288A font-semibold opacity-50 text-lg">
            Max Investment Limit
          </p>
          <p className="text-lg font-semibold md:text-xl">{props.Max}</p>
        </div>
      </div>
    </div>
  );
}

export default InvestPlan;
