import React from "react";
import { useState, useEffect } from "react";
// import {debounce} from 'lodash';
import Input from "../../components/styledComponents/Input/Input";
import YLD from "../../assets/images/yld.svg";
import DownArrow from "../../assets/images/down_arrow.svg";
import { useSelector } from "react-redux";
import {
  GetTokenPairApi,
  GetExchangeRatesApi,
  ExecuteTransactionApi,
} from "../../apis/swap/swapApis";
import swapIcon from "../../assets/images/swap_icon.svg";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";
import Button from "../../components/styledComponents/Buttons/Button";
import LoadingSpinner from "../../components/spinner/spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const Swap = ({ data }) => {
  console.log("data", data);
  const [showFromList, setShowFromList] = useState(false);
  const [showToList, setShowToList] = useState(false);
  const [fromAmount, setFromAmount] = useState();
  const [toAmount, setToAmount] = useState();

  const [toData, setToData] = useState();

  const [transactionToken, setTransactionToken] = useState();

  const [timer, setTimer] = useState(0);
  const [refreshLoading, setRefreshLoading] = useState(false);
  // const debounceLoadData = useCallback(debounce(getExchangeRateFunction, 1000), []);

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const handleFromClick = () => {
    setShowFromList(!showFromList);
  };

  const handleToClick = () => {
    setShowToList(!showToList);
  };

  const [swap, setSwap] = useState({
    curr_src: "",
    curr_name: "",
    curr_tokenAddress: "",
    curr_secretType: "",
  });

  const [swapTo, setSwapTo] = useState({
    curr_src: "",
    curr_name: "",
    curr_tokenAddress: "",
    curr_secretType: "",
  });

  async function getExchangeRateFunction() {
    const data = {
      fromSecretType: swap.curr_secretType,
      toSecretType: swapTo.curr_secretType,
      fromToken: swap.curr_tokenAddress,
      toToken: swapTo.curr_tokenAddress,
      amount: fromAmount,
    };
    const result = await GetExchangeRatesApi(data);
    console.log(result);
    setRefreshLoading(false);
    setTimer(10);
    setToAmount(result.data.response.outputAmount);
    setTransactionToken(result.data.transaction_token);
  }

  async function onTransactionExecution() {
    const data = { transaction: transactionToken };
    // console.log(data);
    const result = await ExecuteTransactionApi(data);
    console.log(result);
    if (result.isSuccess) {
      toast.success("Swapping Successful", {
        position: toast.POSITION.TOP_CENTER,
      });
      setRefreshLoading(false);
      setToAmount("");
      setFromAmount("");
      setTransactionToken("");
      setSwap({
        curr_src: "",
        curr_name: "",
        curr_tokenAddress: "",
        curr_secretType: "",
      });
      setSwapTo({
        curr_src: "",
        curr_name: "",
        curr_tokenAddress: "",
        curr_secretType: "",
      });
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, [1000]);
    }
  }, [timer]);

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      5000
    );
  }, [showApiRes.visible]);

  useEffect(() => {
    if (swap.curr_name) {
      let toData = data.filter((item) => item.from.symbol === swap.curr_name);
      setToData(toData);
      console.log("todata", toData);
    }
  }, [swap.curr_name]);

  useEffect(() => {
    if (swapTo.curr_tokenAddress && fromAmount > 0) {
      setTimeout(() => {
        getExchangeRateFunction();
      }, [1000]);
    }
  }, [swapTo.curr_tokenAddress, fromAmount]);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between items-center mb-4 cursor-pointer select-none">
        <div className="flex flex-col w-[40%]">
          <p className="mb-2 font-bold text-md">From</p>
          <input
            className="text-lg font-semibold bg-transparent border-none focus:outline-none"
            placeholder="0.0"
            onChange={(e) => {
              setFromAmount(e.target.value);
            }}
            value={fromAmount}
          ></input>
        </div>

        <div
          className="bg-[#34CFFF] relative flex font-semibold text-lg dropbtn w-full rounded-[8px] w-[60%]"
          onClick={handleFromClick}
        >
          <div className="pl-[16px] pr-[16px] py-[8px]">
            {swap.curr_src && (
              <img
                src={swap.curr_src}
                alt="YLD"
                className="dropbtn mr-2 h-[20px]"
              />
            )}
            {swap.curr_name ? swap.curr_name : "Select a token"}
          </div>
          {!showFromList && (
            <img
              src={DownArrow}
              alt="Down arrow"
              className="dropbtn absolute right-[8px] -translate-y-1/2 top-[50%]"
            />
          )}
          {showFromList && (
            <div
              className="dropdown absolute px-4 py-4 w-full top-[101%] rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px_20px_#C0EFFF] opacity-100"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
            >
              <div className="" role="none">
                {data.map((val, key) => (
                  <div
                    key={key}
                    value={key}
                    tabIndex={key}
                    className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid"
                    onMouseUp={() => {
                      setSwap({
                        // curr_src: val.logo,
                        curr_name: val.from.symbol,
                        curr_tokenAddress: val.from.tokenAddress,
                        curr_secretType: val.from.secretType,
                      });
                    }}
                  >
                    <div className="flex w-full px-4 py-2">
                      <div className="flex items-center justify-center text-lg font-semibold">
                        {/* <img
                      src={val.logo}
                      alt={val.symbol}
                      className="mr-2 h-[20px]"
                    /> */}
                        <p>{val.from.symbol}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <img
        src={swapIcon}
        alt="swap"
        width={"25px"}
        height={"25px"}
        className="mb-4"
      />

      <div className="w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between items-center mb-4 cursor-pointer select-none">
        <div className="flex flex-col w-[40%]">
          <p className="mb-2 font-bold text-md">To</p>
          <input
            disabled
            className="text-lg font-semibold bg-transparent border-none focus:outline-none"
            placeholder="0.0"
            value={toAmount && toAmount}
          ></input>
        </div>
        <div
          className="bg-[#34CFFF] relative flex font-semibold text-lg dropbtn w-full rounded-[8px]"
          onClick={() => {
            if (swap.curr_name) {
              handleToClick();
            } else {
              setShowApiRes({
                message: "Please select token to swap",
                visible: true,
                variant: "warning",
                title: "Warning",
              });
            }
          }}
        >
          <div className="pl-[16px] pr-[16px] py-[8px]">
            {swapTo.curr_src && (
              <img
                src={swapTo.curr_src}
                alt="YLD"
                className="dropbtn mr-2 h-[20px]"
              />
            )}
            {swapTo.curr_name ? swapTo.curr_name : "Select a token"}
          </div>
          {!showToList && (
            <img
              src={DownArrow}
              alt="Down arrow"
              className="dropbtn absolute right-[8px] -translate-y-1/2 top-[50%]"
            />
          )}
          {showToList && (
            <div
              className="dropdown absolute px-4 py-4 w-full top-[101%] rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px_20px_#C0EFFF] opacity-100"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
            >
              <div className="" role="none">
                {toData &&
                  toData.map((val, key) => (
                    <div
                      key={key}
                      value={key}
                      tabIndex={key}
                      className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid"
                      onMouseUp={() => {
                        setSwapTo({
                          // curr_src: val.logo,
                          curr_name: val.to.symbol,
                          curr_tokenAddress: val.to.tokenAddress,
                          curr_secretType: val.to.secretType,
                        });
                      }}
                    >
                      <div className="flex w-full px-4 py-2">
                        <div className="flex items-center justify-center text-lg font-semibold">
                          {/* <img
                      src={val.logo}
                      alt={val.symbol}
                      className="mr-2 h-[20px]"
                    /> */}
                          <p>{val.to.symbol}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="">
        {showApiRes.visible && (
          <div className="mt-4">
            <MessageBox
              width="100%"
              variant={showApiRes.variant}
              message={showApiRes.message}
              title={showApiRes.title}
            ></MessageBox>
          </div>
        )}
      </div>

      {/* <div className="flex flex-col items-center w-[80%] mb-4">
        <Input placeholder="Amount" className="w-full" type="number" />
      </div> */}
      {/* <div className="border rounded-2xl px-4 py-4 border-[#74D5F3] w-full">
        <p className="font-semibold text-lg text-[#00C3FF] pb-2">
          You will receive
        </p>
        <div className="flex gap-2 bg-[#E5F9FF] rounded-2xl px-6 py-3 items-center h-full">
          <div>
            <img src={YLD} alt="YLD" className="w-[80%]" />
          </div>
          <p className="font-semibold text-lg text-[#002C3A] opacity-50">
            YLD 0.000
          </p>
        </div>
      </div> */}
      {fromAmount > 0 && swapTo && (
        <div className="flex justify-center w-full md:py-2">
          {timer === 0 ? (
            <Button
              className="w-[50%] flex items-center justify-center bg-gray-300"
              variant="outline"
              background="yes"
              onClick={() => {
                setRefreshLoading(true);
                setToAmount();
                getExchangeRateFunction();
              }}
            >
              {refreshLoading ? <LoadingSpinner /> : "Refresh"}
            </Button>
          ) : (
            <Button
              className="w-[50%] flex items-center justify-center"
              onClick={() => {
                setRefreshLoading(true);
                onTransactionExecution();
              }}
            >
              {refreshLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  Continue {toAmount && <div className="ml-2">({timer})</div>}
                </div>
              )}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const DepositInvest = () => {
  const [deposit, setDeposit] = useState(true);
  const [showList, setShowList] = useState(false);
  const handleClick = () => {
    setShowList(!showList);
  };

  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      setShowList(false);
    }
  };

  const data = useSelector((state) => state.wallet.COINS);
  const [swapData, setSwapData] = useState();
  const [curr, setCurr] = useState({
    curr_src: data[0]?.logo,
    curr_name: data[0]?.symbol,
  });

  async function getSwapData() {
    const result = await GetTokenPairApi();
    console.log(result);
    setSwapData(result.data.response);
  }
  return (
    <div>
      <div className="flex flex-col items-center p-4">
        <div className=" w-full border-[1px] border-[#34CFFF] rounded-[20px] bg-[#E5F9FF] flex overflow-hidden mb-4">
          <div
            className={`p-4 rounded-r-[20px] w-[50%] text-center font-semibold cursor-pointer ${
              deposit && "bg-[#34CFFF] "
            }`}
            onClick={() => setDeposit(true)}
          >
            <p>Deposit</p>
          </div>
          <div
            className={`p-4 rounded-l-[20px] w-[50%] text-center font-semibold cursor-pointer ${
              !deposit && "bg-[#34CFFF] "
            }`}
            onClick={() => {
              setDeposit(false);
              getSwapData();
            }}
          >
            <p>Swap</p>
          </div>
        </div>

        {deposit ? (
          <div className="flex flex-col items-center w-full">
            <div
              onClick={handleClick}
              className="dropbtn w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between mb-4 cursor-pointer select-none"
            >
              <div className="flex items-center justify-center text-lg font-semibold  dropbtn">
                <img
                  src={curr.curr_src}
                  alt="YLD"
                  className="dropbtn mr-2 h-[20px]"
                />
                {/* YLD */}
                {curr.curr_name}
              </div>
              <div className="flex items-center justify-center ">
                {!showList && (
                  <img src={DownArrow} alt="Down arrow" className="dropbtn" />
                )}
              </div>
            </div>

            {showList && (
              <div
                className="dropdown origin-top-right absolute mt-20 w-[80%] px-4 py-4 rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px_20px_#C0EFFF] opacity-100"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className="" role="none">
                  {data.map((val, key) => (
                    <div
                      key={key}
                      value={key}
                      tabIndex={key + 1}
                      className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid"
                      onMouseUp={() => {
                        setCurr({ curr_src: val.logo, curr_name: val.symbol });
                      }}
                    >
                      <div className="flex w-full px-4 py-4">
                        <div className="flex items-center justify-center text-lg font-semibold">
                          <img
                            src={val.logo}
                            alt={val.symbol}
                            className="mr-2 h-[20px]"
                          />
                          <p>{val.symbol}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="flex flex-col items-center w-[80%] mb-4">
              <Input placeholder="Amount" className="w-full" type="number" />
            </div>
            <div className="flex justify-center w-full md:py-2">
              <Button className="w-[50%]"> Continue</Button>
            </div>
          </div>
        ) : (
          swapData && <Swap data={swapData} />
        )}
      </div>
    </div>
  );
};

export default DepositInvest;
