import React from "react";
import Wallet from "../../../assets/images/wallet.svg";
import Profile from "../../../assets/images/profile.svg";
import Portfolio from "../../../assets/images/portfolio.svg";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Overview() {
  const holding = useSelector((state) => state.wallet.HOLDINGS);

  return (
    <div className="rounded-2xl bg-white py-4 px-2 lg:px-6 ">
      <div className="py-2 px-2   ">
        <p className="font-semibold text-2xl md:text-3xl color-[#002C3A]">
          Overview
        </p>
      </div>
      <div className="border-solid border border-[#74D5F3] rounded-2xl mx-2 my-2 py-2 px-4">
        <div className="flex gap-2 py-2">
          <img src={Profile} alt="Profile" />
          <p className="text-[#00C3FF] font-semibold text-lg">Account Value</p>
        </div>
        <div className="">
          <p className="text-3xl md:text-5xl font-black pb-2">
            {/* $ 0.00 */}{" "}
            {holding ? "$ " + holding.account.toFixed(3) : <Skeleton />}
          </p>
        </div>
      </div>
      <div className="border-solid border border-[#74D5F3] rounded-2xl my-2 mx-2 ">
        <div className="border-b border-[#74D5F3] py-2 px-4">
          <div className="flex gap-2 py-2">
            <img src={Portfolio} alt="Portfolio" />
            <p className="text-[#959BC9] font-semibold text-lg">
              Portfolio Value
            </p>
          </div>
          <div className="">
            <p className="text-[#002C3A] font-semibold text-2xl md:text-4xl pb-2">
              {/* $ 0.00 */}{" "}
              {holding ? "$ " + holding.portfolio.toFixed(3) : <Skeleton />}
            </p>
          </div>
        </div>
        <div className="py-2 px-4">
          <div className="flex gap-2 py-2 opacity-50">
            <img src={Wallet} alt="Wallet" />
            <p className="text-[#1D288A] font-semibold text-lg">Wallet</p>
          </div>
          <div>
            <p className="text-[#002C3A] font-semibold text-2xl md:text-3xl pb-2">
              {/* $ 0.00 */}{" "}
              {holding ? "$ " + holding.wallet.toFixed(3) : <Skeleton />}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
