import React, { useEffect, useState } from "react";
import PasswordImage from "../../assets/images/password_security.svg";
import Input from "../../components/styledComponents/Input/Input";
import Button from "../../components/styledComponents/Buttons/Button";
import { ChangePasswordApi } from "../../apis/profile/getUserProfile";
import MessageBox from "../../components/styledComponents/MessageBox/MessageBox";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const SettingsSecurityChangePassword = () => {
  const [show, setShow] = useState(true);
  const [oldpassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onConfirmPassword = (e) => {
    const confirmpassword = e.target.value;
    setConfirmPassword(confirmpassword);
  };

  const onOldPassword = (e) => {
    const confirmpassword = e.target.value;
    setOldPassword(confirmpassword);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (password.length < 8) {
      setShowApiRes({
        message: "Password must be atleast 8 characters",
        visible: true,
        variant: "warning",
        title: "Warning",
      });
    } else if (password !== confirmpassword) {
      setShowApiRes({
        message: "Passwords does not match",
        visible: true,
        variant: "danger",
        title: "Warning",
      });
    } else if (password === confirmpassword) {
      ChangePasswordFunction();
    }
  }

  async function ChangePasswordFunction() {
    const result = await ChangePasswordApi({
      oldPassword: oldpassword,
      newPassword: password,
    });

    if (result.isSuccess) {
      setOldPassword("");
      setPassword("");
      setConfirmPassword("");
      setShow(false);
      toast.success("Password Changed Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShowApiRes({ ...showApiRes, visible: false });
    }, 5000);
  }, [showApiRes.visible]);

  return (
    <div>
      <div className="flex flex-col items-center justify-center sm:flex-row">
        <div className="pt-4 sm:max-w-[60%] flex flex-col items-center justify-center">
          <div className="mb-4 max-w-[40%]">
            <img src={PasswordImage} alt="" />
          </div>
          <div className="mb-4 text-center">
            <h1 className="text-[#002C3A] font-semibold text-xl sm:text-3xl">
              Password Security
            </h1>
          </div>
          <div className="px-4 text-center sm:px-6">
            <p className="text-[#959BC9] font-semibold text-md sm:text-lg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              imperdiet efficitur facilisis. Nunc imperdiet ultrices justo, sit
              amet gravida dui gravida in. Nullam non ipsum vel augue tempor
              vehicula ac sed tellus. Duis a risus rhoncus, rutrum leo vitae,
              feugiat risus. Donec mollis, tortor ut malesuada sollicitudin,
              purus augue euismod arcu, vitae elementum dolor ligula vitae
              ipsum. Sed in magna nunc.
            </p>
          </div>
        </div>
        <div className="h-full w-[90%] sm:w-[40%] py-4 my-8">
          <div className="border-[1px]  border-[#C4C4C4] rounded-[20px] h-full w-full">
            <div className="border-[#C4C4C4] border-b-[1px] p-4 sm:p-6">
              <h1 className="text-lg font-semibold sm:text-2xl">
                {" "}
                Change Password
              </h1>
            </div>
            <div className="w-full px-6 py-2">
              {show ? (
                <form onSubmit={handleSubmit}>
                  <div className="w-full pt-4">
                    <Input
                      className="w-full"
                      placeholder="Old Password"
                      type="password"
                      onChange={onOldPassword}
                      value={oldpassword}
                    />
                  </div>
                  <div className="w-full pt-4">
                    <Input
                      className="w-full"
                      placeholder="New Password"
                      type="password"
                      onChange={onChangePassword}
                      value={password}
                    />
                  </div>
                  <div className="w-full pt-4">
                    <Input
                      className="w-full"
                      placeholder="Re-enter New Password"
                      type="password"
                      onChange={onConfirmPassword}
                      value={confirmpassword}
                    />
                  </div>
                  <div className="">
                    {showApiRes.visible && (
                      <div className="">
                        <MessageBox
                          width="100%"
                          variant={showApiRes.variant}
                          message={showApiRes.message}
                          title={showApiRes.title}
                        ></MessageBox>
                      </div>
                    )}
                  </div>
                  <div className="w-full mt-4 mb-4">
                    <Button className="w-full" type="submit">
                      Update Password
                    </Button>
                  </div>
                </form>
              ) : (
                <Button
                  className="w-full"
                  variant="outline"
                  color="green"
                  disabled
                >
                  Password Updated Successfully
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsSecurityChangePassword;
